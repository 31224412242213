import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Timer } from '../../live/Utils/Timer';
import { BuyNowModal } from '../../live/ModalSteps/BuyNowModal';
import { MakeOfferModal } from '../../live/ModalSteps/MakeOfferModal';
import { useTranslation } from 'react-i18next';

export const VehicleActionSideBarLive = (props) => {
  const { vehicle } = props;  
  const [openBuyModal, setOpenBuyModal] = React.useState(false);
  const [openMakeOfferModal, setOpenMakeOfferModal] = React.useState(false);

  const handleOpenBuyModal = () => setOpenBuyModal(true);
  const handleCloseBuyModal = () => setOpenBuyModal(false);

  const handleOpenMakeOfferModal = () => {
    setOpenMakeOfferModal(true);
  };

  const handleCloseMakeOfferModal = () => {
    setOpenMakeOfferModal(false);
  };

  //const dateLive = '1/25/2023 1:00:00 PM';
  const dateLive = vehicle.dateLive.replace('T', ' ') + ' UTC';
  const duration = vehicle.duration;
  const addedToBlockDateParsed = Date.parse(dateLive);
  const deadline = addedToBlockDateParsed + duration * 60000;
  const isExpired = Date.now() > deadline;

  const priceFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  const { t } = useTranslation();

  return (
    <Paper sx={{ flexGrow: 1, padding: 2 }}>
      <Stack spacing={2} width='100%'>
        <Typography
          component='span'
          variant='span'
          fontWeight='500'
          align='center'
          fontSize='20px'
        >
          {isExpired ? 'Expired' : <Timer deadline={new Date(deadline)} />}
        </Typography>
        <Divider />
        {vehicle.showPrice && (
          <>
            <Typography
              component='span'
              variant='h4'
              color='primary'
              fontWeight='500'
              align='center'
            >
              {priceFormatter.format(vehicle.price)}
            </Typography>
            <Button
              fullWidth
              color='primary'
              variant='contained'
              size='large'
              disabled={isExpired}
              onClick={handleOpenBuyModal}
            >
              {t('Buy it Now')}
            </Button>
            <Divider />
          </>
        )}
        <Typography component='span' variant='h5' fontWeight='500' align='center'>
          {vehicle.offers} offer{vehicle.offers > 1 ? 's' : ''}
        </Typography>
        <Button
          fullWidth
          variant='outlined'
          size='large'
          disabled={isExpired || !vehicle.canMakeOffer}
          onClick={handleOpenMakeOfferModal}
        >
          {t('Make Offer')}
        </Button>
      </Stack>
      <BuyNowModal
        open={openBuyModal}
        onClose={handleCloseBuyModal}
        vehicleData={{ ...vehicle.basicInformation, ...vehicle }}
      />
      <MakeOfferModal
        open={openMakeOfferModal}
        onClose={handleCloseMakeOfferModal}
        vehicleData={{ ...vehicle.basicInformation, ...vehicle }}
      />
    </Paper>
  );
};
