import * as React from 'react';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { EditorState, convertFromRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';

export const VehicleNotes = (props) => {
  const { notes, languageId } = props;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    const note = languageId == 1 ? notes.en : notes.fr;
    if (isJsonString(note)) {
      const contentState = convertFromRaw(JSON.parse(note));
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, []);

  return (
    <Paper sx={{ flexGrow: 1, padding: 2 }}>
      <Box m={1}>
        <Typography component='span' variant='h5' fontWeight='500'>
          Notes
        </Typography>
        <Divider sx={{ my: 2 }} />
      </Box>
      <Box m={1}>
        <div
          dangerouslySetInnerHTML={{
            __html: convertToHTML(editorState.getCurrentContent()),
          }}
        ></div>
      </Box>
    </Paper>
  );
};
