import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PhotoSlider } from 'react-photo-view';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-photo-view/dist/react-photo-view.css';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const VehicleImages = (props) => {
    const { images, imagesCustom } = props;
    const [itemData, setItemData] = useState([]);
    const [itemDataFull, setItemDataFull] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);

    const [customImageList, setCustomImageList] = useState([]);
    const [customImageUrls, setCustomImageUrls] = useState([]);

    const [showAllImages, setShowAllImages] = useState(false);

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpenPhotoViewer, setIsOpenPhotoViewer] = useState(false);

    const theme = useTheme();
    const match_md = !useMediaQuery(theme.breakpoints.up('md'));

    React.useEffect(() => {
        setImageList(images);
        const urls = Object.values(images)
            .filter((url) => url !== null)
            .map((url) => url);
        setImageUrls(urls);

        if (imagesCustom) {
            setCustomImageList(imagesCustom);
            const customUrls = imagesCustom.map((item) => item.original);
            setCustomImageUrls(customUrls);            
        }
    }, [images, imagesCustom]);

    useEffect(() => {
        let tempItemDataFull = []
        let tempItemData = [
            {
                img:
                    imageList.front?.medium ??
                    'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg',
                originalImg:
                    imageList.front?.original ??
                    'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg',
                title: 'frontUrl',
                rows: 3,
                cols: match_md ? 8 : 4,
            },
            {
                img:
                    imageList.right?.medium ??
                    'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg',
                originalImg:
                    imageList.right?.original ??
                    'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg',
                title: 'rightUrl',
                rows: match_md ? 1 : 2,
                cols: match_md ? 2 : 1,
            },
            {
                img:
                    imageList.left?.medium ??
                    'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg',
                originalImg:
                    imageList.left?.original ??
                    'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg',
                title: 'leftUrl',
                rows: match_md ? 1 : 2,
                cols: match_md ? 2 : 1,
            },
            {
                img:
                    imageList.back?.medium ??
                    'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg',
                originalImg:
                    imageList.back?.original ??
                    'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg',
                title: 'backUrl',
                rows: match_md ? 1 : 2,
                cols: 2,
            },
            {
                img:
                    imageList.dashboard?.medium ??
                    'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg',
                originalImg:
                    imageList.dashboard?.original ??
                    'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg',
                title: 'dashboardUrl',
                rows: 1,
                cols: match_md ? 1 : 2,
            },
            {
                img:
                    imageList.interior?.medium ??
                    'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg',
                originalImg: imageList.interior?.original ??
                    'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg',
                title: 'interiorUrl',
                rows: 1,
                cols: match_md ? 1 : 2,
            },

        ];

        tempItemData.forEach(item => tempItemDataFull.push(item));

        const maxImages = showAllImages ? customImageList.length : Math.max(0, 9 - tempItemData.length);

        customImageList.forEach((image, index) => {
            const item = {
                img: image.medium,
                originalImg: image.original,
                title: `Custom Image ${index + 1}`,
                rows: 2,
                cols: 2,
            };
                        
            tempItemDataFull.push(item);

            if (index < maxImages) {
                tempItemData.push(item);
            }
        });

        if (!showAllImages && customImageList.length > maxImages) {
            tempItemData.push({
                title: "Show More",
                rows: 2,
                cols: 2,
                isShowMore: true
            });
        } else if (showAllImages) {
            tempItemData.push({
                title: "Show Less",
                rows: 2,
                cols: 2,
                isShowLess: true
            });
        }

        setItemData(tempItemData);
        setItemDataFull(tempItemDataFull);

    }, [imageList, customImageList, match_md, showAllImages]);

    function srcset(image, size, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    function QuiltedImageList() {
        return (
            <ImageList
                sx={{ maxWidth: 1800, marginTop: 0, cursor: 'pointer' }}
                variant='quilted'
                cols={8}
                rowHeight={121}
            >
                {itemData?.map((item, index) => (
                    <ImageListItem
                        key={index}
                        cols={item.cols || 1}
                        rows={item.rows || 1}
                        onClick={() => {
                            if (item.isShowMore || item.isShowLess) {
                                setShowAllImages(prev => !prev);
                            } else {
                                const photoIdx = itemData.slice(0, index).filter((imgItem) => !(imgItem.isShowMore || imgItem.isShowLess)).length;
                                setPhotoIndex(photoIdx);
                                setIsOpenPhotoViewer(true);
                            }
                        }}
                    >

                        {item.isShowMore || item.isShowLess ?
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}
                            >
                                <Button
                                    variant="outlined"
                                >
                                    {item.isShowMore ? "SHOW MORE" : "SHOW LESS"}
                                </Button>
                            </Box>
                            :
                            <img
                                src={item.img}
                                alt={item.title}
                                loading='lazy'
                            />
                        }

                    </ImageListItem>


                ))}
            </ImageList>


        );
    }

    return (
        <>

            <QuiltedImageList />
            <PhotoSlider
                toolbarRender={({ rotate, onRotate }) => {
                    return (
                        <>
                            <RotateLeftIcon sx={{ mx: 1 }} onClick={() => onRotate(rotate - 90)} />
                            <RotateRightIcon sx={{ mx: 1 }} onClick={() => onRotate(rotate + 90)} />
                        </>
                    );
                }}
                images={itemDataFull
                    .filter((item) => !(item.isShowMore || item.isShowLess))
                    .map((item, i) => ({ src: item.originalImg || item.img, key: i }))
                }
                visible={isOpenPhotoViewer}
                onClose={() => setIsOpenPhotoViewer(false)}
                index={photoIndex}
                onIndexChange={setPhotoIndex}
                loadingElement={<CircularProgress />}
                brokenElement={<WarningAmberIcon />}
            />

        </>
    );
};
