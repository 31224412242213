import * as React from 'react';

export default function TermsAndConditionsEN() {
  return (
    <div id='description'>
      <p>
        <strong>Types of Actions:</strong>
      </p>
      <p>
        <u>1.1. Buy It Now Price:</u> The Buy It Now price represents the seller&apos;s
        asking price and constitutes a binding offer to sell the vehicle. By selecting the
        Buy It Now option, the Buyer accepts the offer and agrees to purchase the vehicle
        at the specified price. The Buyer must immediately submit payment to secure the
        transaction, and the vehicle will be marked as Pending Sold.
      </p>
      <p>
        <u>1.2. Pending Sold:</u> The vehicle will remain in Pending Sold status until the
        transaction is completed. The transaction will only be considered complete once
        the seller confirms the sale and the Buyer submits payment. If the transaction is
        not completed within the time specified by Shopitrade, the vehicle will be placed
        back on the market.
      </p>
      <p>
        <u>1.3. Offers Below Buy It Now Price:</u> Bidders may submit a single offer to
        purchase a vehicle at a price below the Buy It Now price. Offers will be displayed
        in the <b>My Offers</b> section of the site. If a seller accepts a bid, the
        vehicle will be marked as Pending Sold, and the Buyer must submit payment to
        complete the transaction.
      </p>
      <p>
        <u>1.4. Arbitration:</u> Any disputes arising from transactions involving vehicles
        sold through Shopitrade shall be settled in accordance with the arbitration
        procedures established by Shopicar Inc. The arbitration shall take place in the
        Province of Quebec, and the laws of Quebec shall govern the arbitration.
      </p>
      <p>
        <strong>Governing Law and Jurisdiction:</strong>
      </p>
      <p>
        These terms and conditions shall be governed by and construed in accordance with
        the laws of the Province of Quebec, without giving effect to its conflict of laws
        provisions. Any dispute arising out of or related to these terms and conditions
        shall be brought exclusively in the courts of the Province of Quebec.
      </p>
      <p>
        <strong>Payment:</strong>
      </p>
      <p>
        Payment for all vehicles purchased through Shopitrade must be made in full before
        the vehicle can be released. Payment must be made via the payment methods provided
        on our website. We reserve the right to cancel any transaction if we suspect fraud
        or any other illegal activity.
      </p>
      <p>
        <strong>Vehicle Information:</strong>
      </p>
      <p>
        We provide vehicle information to the best of our knowledge, but we do not
        guarantee its accuracy. We encourage Buyers to inspect the vehicle before
        purchasing.
      </p>
      <p>
        <strong>Warranty:</strong>
      </p>
      <p>We do not offer any warranty for the vehicles sold on our website.</p>
      <p>
        <strong>Return Policy:</strong>
      </p>
      <p>We do not accept returns on any vehicles sold on our website.</p>
      <p>
        <strong>Privacy Policy:</strong>
      </p>
      <p>
        We take your privacy seriously and will not share your information with any
        third-party without your consent. Please refer to our Privacy Policy for more
        information.
      </p>
      <p>
        <strong>Limitation of Liability:</strong>
      </p>
      <p>
        We are not responsible for any damages or losses arising from the use of our
        website or the vehicles sold on our website.
      </p>
      <p>
        <strong>Modification of Terms and Conditions:</strong>
      </p>
      <p>
        We reserve the right to modify these terms and conditions at any time without
        notice. It is the Buyer&apos;s responsibility to regularly review these terms and
        conditions.
      </p>
      <p>Thank you for using Shopitrade!</p>
    </div>
  );
}
