import { useQuery } from '@tanstack/react-query';
import { adminApi } from '@/lib/axios';

export function getVehiclesSoldHistory(data) {
  return adminApi.post('/tradeInBlock/get-sold-history', data);
}

export const useVehiclesSoldHistory = ({ data, config }) => {
  return useQuery({
    queryKey: ['get sold history', data],
    queryFn: () => getVehiclesSoldHistory(data),
    ...config,
  });
};
