// MUI Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const SpecTypo = styled(Typography)({
  component: 'span',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const SpecBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 5,
  borderRadius: '4px',
});

const SpecCard = ({label, icon, value}) => {
  return (
    <SpecBox>
      {icon}
      <Box display='grid' ml={2}>
        <SpecTypo fontWeight='light'>
          {label}
        </SpecTypo>
        <SpecTypo fontWeight='medium'>
          {value ? value : 'N/A'}
        </SpecTypo>
      </Box>
    </SpecBox>
  );
};

export default SpecCard;
