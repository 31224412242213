import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { BuyNowModal } from '../ModalSteps/BuyNowModal';
import { MakeOfferModal } from '../ModalSteps/MakeOfferModal';
import { useTranslation } from 'react-i18next';

export const ActionButtons = (props) => {  
  const { disabled = false, canMakeOffer, vehicleData } = props;
  const [openBuyModal, setOpenBuyModal] = React.useState(false);
  const handleOpenBuyModal = () => setOpenBuyModal(true);
  const handleCloseBuyModal = () => setOpenBuyModal(false); 
  const [openMakeOfferModal, setOpenMakeOfferModal] = React.useState(false);

  const handleOpenMakeOfferModal = () => {
    setOpenMakeOfferModal(true);
  };

  const handleCloseMakeOfferModal = () => {
    setOpenMakeOfferModal(false);
  };
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container spacing={1}>
        {/* {vehicleData.showPrice && vehicleData.price !== 0 && (
          <Grid item xs={12}>
            <Button
              fullWidth={true}
              disabled={disabled}
              color='primary'
              variant='contained'
              size='large'
              onClick={handleOpenBuyModal}
            >
              {t('Buy it Now')}
            </Button>
          </Grid>
        )} */}
        <Grid item xs={12}>
          <Button
            fullWidth
            disabled={disabled || !canMakeOffer}
            variant='outlined'
            size='large'
            onClick={handleOpenMakeOfferModal}
          >
            {t('Make Offer')}
          </Button>
        </Grid>
        {vehicleData.carFaxId && (
          <Grid item xs={12}>
            <Button
              fullWidth
              variant='outlined'
              size='large'
              href={`https://vhr.carfax.ca/?id=${vehicleData.carFaxId}`}
              target='_blank'
            >
              <img
                src='https://shopicar-images.s3.amazonaws.com/0/carfax.jpg'
                alt='CarFax Report'
                style={{ width: '100%' }}
              />
            </Button>
          </Grid>
        )}
      </Grid>

      <BuyNowModal
        open={openBuyModal}
        onClose={handleCloseBuyModal}
        vehicleData={vehicleData}
      />
      <MakeOfferModal
        open={openMakeOfferModal}
        onClose={handleCloseMakeOfferModal}
        vehicleData={vehicleData}
      />
    </Box>
  );
};
