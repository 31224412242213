import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { styled } from '@mui/material/styles';
import { Filter } from './Filter';
import {
  RangeSlider,
  MultiSelectionChipList,
  SingleSelectionChipList,
} from '@/components/Inputs';
import { getUnitFormatter, getPriceFormatter } from '@/utils/numberFormats';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';

const sortings = ['Price', 'Year', 'Mileage', 'Make', 'Model', 'Recent'];

const ScrollBoxWrapper = styled(Box)`
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #c7c7c7;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #29aae2;
  }
`;
  export const Filters = ({
    setFiltersId,
    setFilters,
    filterValues = {
      makeModels: [],
      yearRange: [0, 0],
      priceRange: [0, 0],
      mileageRange: [0, 0],
      bodies: [],
      transmissions: [],
      fuels: [],
      drivetrains: [],
      sellerTypes: [],
      colors: []
    }
  }) => {

  const { t } = useTranslation();

  const [selectedModels, setSelectedModels] = React.useState(
    Array(filterValues.makeModels ? filterValues.makeModels.length : 0).fill([])
  );
  const [selectedSorting, setSelectedSorting] = React.useState('date');
  const [selectedYearMin, setSelectedYearMin] = React.useState(filterValues.yearRange[0]);
  const [selectedYearMax, setSelectedYearMax] = React.useState(filterValues.yearRange[1]);
  const [selectedPriceMin, setSelectedPriceMin] = React.useState(
    filterValues.priceRange[0]
  );
  const [selectedPriceMax, setSelectedPriceMax] = React.useState(
    filterValues.priceRange[1]
  );
  const [selectedMileageMin, setSelectedMileageMin] = React.useState(
    filterValues.mileageRange[0]
  );
  const [selectedMileageMax, setSelectedMileageMax] = React.useState(
    filterValues.mileageRange[1]
  );
  const [selectedBodies, setSelectedBodies] = React.useState([]);
  const [selectedTransmissions, setSelectedTransmissions] = React.useState([]);
  const [selectedFuels, setSelectedFuels] = React.useState([]);
  const [selectedDrivetrains, setSelectedDrivetrains] = React.useState([]);
  const [selectedSellerTypes, setSelectedSellerTypes] = React.useState([]);
  const [selectedColors, setSelectedColors] = React.useState([]);

  React.useEffect(() => {
    setFilters({
      SortBy: selectedSorting,
      MakeId: [], // Unused
      ModelId: selectedModels.reduce(
        (accumulator, currentValue) => accumulator.concat(currentValue),
        []
      ),
      YearMin: selectedYearMin,
      YearMax: selectedYearMax,
      PriceMin: selectedPriceMin,
      PriceMax: selectedPriceMax,
      MileageMin: selectedMileageMin,
      MileageMax: selectedMileageMax,
      BodyTypeId: selectedBodies,
      TransmissionId: selectedTransmissions,
      FuelTypeId: selectedFuels,
      DrivetrainId: selectedDrivetrains,
      SellerTypeId: selectedSellerTypes,
      ColorId: selectedColors,
    });
  }, [
    selectedModels,
    selectedYearMin,
    selectedYearMax,
    selectedPriceMin,
    selectedPriceMax,
    selectedMileageMin,
    selectedMileageMax,
    selectedBodies,
    selectedTransmissions,
    selectedFuels,
    selectedDrivetrains,
    selectedSellerTypes,
    selectedColors,
  ]);

  const setSelectedModelsOfMake = (index) => (value) => {
    const copy = selectedModels.slice();
    copy[index] = value;
    setSelectedModels(copy);
  };

  const handleFilterReset = () => {
    setFiltersId(Date.now());
  };

  const canResetFilters =
    selectedModels.reduce(
      (accumulator, currentValue) => accumulator.concat(currentValue),
      []
    ).length !== 0 ||
    selectedBodies.length !== 0 ||
    selectedTransmissions.length !== 0 ||
    selectedFuels.length !== 0 ||
    selectedDrivetrains.length !== 0 ||
    selectedSellerTypes.length !== 0 ||
    selectedColors.length !== 0 ||
    selectedYearMin !== filterValues?.yearRange[0] ||
    selectedYearMax !== filterValues?.yearRange[1] ||
    selectedPriceMin !== filterValues?.priceRange[0] ||
    selectedPriceMax !== filterValues?.priceRange[1] ||
    selectedMileageMin !== filterValues?.mileageRange[0] ||
    selectedMileageMax !== filterValues?.mileageRange[1];

  const FilterTop = React.useMemo(
    () => (
      <>
        {canResetFilters ? (
          <Box width='100%' px={2} display='flex' sx={{ mb: 2 }}>
            <Button
              variant='outlined'
              onClick={handleFilterReset}
              fullWidth
              startIcon={<RefreshRoundedIcon sx={{ color: 'primary' }} />}
            >
              {t('Reset')}
            </Button>
          </Box>
        ) : (
          ''
        )}
      </>
      // <Paper
      //   sx={{
      //     height: 52,
      //     display: 'flex',
      //     alignItems: 'center',
      //     fontWeight: 'normal',
      //     borderRadius: '0px',
      //   }}
      // >
      //   <Typography
      //     px={1}
      //     variant='button'
      //     sx={{
      //       fontSize: 17,
      //       fontWeight: '500',
      //       backgroundColor: 'none',
      //     }}
      //   >
      //     Filters
      //   </Typography>
      //   {canResetFilters && (
      //     <Box width='100%' px={1} display='flex' justifyContent='right'>
      //       <Button
      //         variant='outlined'
      //         size='small'
      //         onClick={handleFilterReset}
      //         startIcon={<RefreshRoundedIcon sx={{ color: 'primary' }} />}
      //       >
      //         Reset
      //       </Button>
      //     </Box>
      //   )}
      // </Paper>
    ),
    [canResetFilters,t]
  );

  const SortingFilter = React.useMemo(
    () => (
      <Filter title={t('Sorting')}>
        <SingleSelectionChipList
          chips={sortings}
          selectedChip={selectedSorting}
          setSelectedChip={setSelectedSorting}
        />
      </Filter>
    ),
    [selectedSorting,t]
  );

  const MakeModelFilter = React.useMemo(
    () => (
      <Filter title={t('Makes and Models')}>
      {filterValues.makeModels && filterValues.makeModels.map((makeModel, index) => (
        <Filter key={index} title={makeModel.name}>
          <MultiSelectionChipList
            chips={makeModel.models || []}
            selectedChips={selectedModels[index] || []}
            setSelectedChips={setSelectedModelsOfMake(index)}
          />
        </Filter>
      ))}
    </Filter>
    ),
    [selectedModels,t]
  );

  const YearFilter = React.useMemo(
    () => (
      <Filter title={t('Year')}>
        <RangeSlider
          step={1}
          range={filterValues.yearRange}
          setMinValue={setSelectedYearMin}
          setMaxValue={setSelectedYearMax}
        />
      </Filter>
    ),
    [selectedYearMin, selectedYearMax, filterValues.yearRange,t]
  );

  const PriceFilter = React.useMemo(
    () => (
      <Filter title={t('Price')}>
        <RangeSlider
          range={filterValues.priceRange}
          setMinValue={setSelectedPriceMin}
          setMaxValue={setSelectedPriceMax}
          formatter={getPriceFormatter('USD')}
        />
      </Filter>
    ),
    [selectedPriceMin, selectedPriceMax, filterValues.priceRange,t]
  );
  
  const mobile = useMediaQuery('(max-width:600px)');

  return (
    <ScrollBoxWrapper
      mr={2}
      sx={{
        position: 'sticky',
        top: 70,
        width: '100%',
        maxHeight: 850,
        overflowY: 'auto',
        mb: 2,
      }}
    >
      <Box width='100%'>
        {mobile ? (
          <Accordion
            defaultExpanded={false}
            key={'filters'}
            sx={{
              p: 0,
              border: '1px solid rgba(0, 0, 0, .125)',
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon color='primary' />}>
              <Typography
                px={1}
                variant='button'
                sx={{
                  fontSize: 17,
                  fontWeight: '500',
                  backgroundColor: 'none',
                }}
              >
                Filters
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              {FilterTop}         
              {MakeModelFilter}
              {YearFilter}
              {PriceFilter}             
            </AccordionDetails>
          </Accordion>
        ) : (
          <Accordion
            defaultExpanded={true}
            key={'filters'}
            sx={{
              p: 0,
              border: '1px solid rgba(0, 0, 0, .125)',
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon color='primary' />}>
              <Typography
                px={1}
                variant='button'
                sx={{
                  fontSize: 17,
                  fontWeight: '500',
                  backgroundColor: 'none',
                }}
              >
                Filters
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              {FilterTop}            
              {MakeModelFilter}
              {YearFilter}
              {PriceFilter}
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </ScrollBoxWrapper>
  );
};
