import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TermsAndConditionsEN from './TermsAndConditionsEN';
import TermsAndConditionsFR from './TermsAndConditionsFR';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CarDetails } from './CarDetails';
import { postBuyNow } from '../../../api/postBuyNow';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';

export default function BuyNowSteps(props) {
  const { onClose, vehicleData } = props;

  const { t, i18n } = useTranslation();

  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const [activeStep, setActiveStep] = React.useState(0);
  const [disableFinishButton, setDisableFinishButton] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setShowErrorMessage(false);
    setDisableFinishButton(false);
    setLoading(false);
  };

  const handleFinish = async (event) => {
    event.preventDefault();
    setDisableFinishButton(true);
    setLoading(true);
    try {
      await postBuyNow({
        id: vehicleData.id,
      });
      setActiveStep(3);
    } catch (error) {
      setShowErrorMessage(true);
      setDisableFinishButton(false);
      setLoading(false);
    }
  };

  const errorMessage = showErrorMessage ? (
    <Alert variant='filled' severity='error' color='warning'>
      <AlertTitle>{t('Error')}</AlertTitle>
      {t('Sorry, an error has occurred, Please try again')}
    </Alert>
  ) : (
    ''
  );

  const steps = [
    {
      title: t('Confirme your purchase'),
      description: t('TermDescription'),
      content: <CarDetails data={vehicleData} />,
      input: '',
      NextButton: (
        <Button container variant='contained' onClick={handleNext}>
          {t('I Confirm')} <KeyboardArrowRightIcon />
        </Button>
      ),
      BackButton: (
        <Button container onClick={onClose} sx={{ mr: 1 }}>
          {t('Back')}
        </Button>
      ),
    },
    {
      title: t('Term and conditions'),
      description: t('TermDescription'),
      errorMessage: { errorMessage },
      NextButton: (
        <LoadingButton
          size='small'
          endIcon={<CheckIcon />}
          loadingPosition='end'
          variant='contained'
          onClick={handleFinish}
          loading={loading}
          disabled={disableFinishButton}
          sx={{ m: 0 }}
        >
          <span sx={{ m: 0 }}>{t('I accept and agree')}</span>
        </LoadingButton>
      ),
      BackButton: (
        <Button onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
      ),
      terms: i18n.language == 'fr' ? <TermsAndConditionsFR /> : <TermsAndConditionsEN />,
    },
    {
      title: 'Completed',
      description: '',
      content: null,
      NextButton: (
        <Button variant='contained' onClick={handleNext}>
          <CheckCircleIcon size='medium' sx={{ mr: 1 }} /> {t('Finish')}
        </Button>
      ),
      BackButton: (
        <Button onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
      ),
      terms: null,
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{step.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Box
            sx={{ mt: 5, mb: 2, maxWidth: '800px', mr: 'auto', ml: 'auto' }}
            align='center'
          >
            <Typography variant='h6' component='h6' sx={{ mt: 2, mb: 1 }}>
              {t('Thank you!')}
            </Typography>
            <Typography sx={{ height: '200' }}>
              {t('The Purchase was successfully completed')}
            </Typography>
            <Typography sx={{ height: '200' }}>
              {t('you can find all your purchase in the Purchases section')}
            </Typography>
            <Button variant='contained' onClick={onClose} sx={{ mt: 5 }}>
              <CheckCircleIcon size='medium' sx={{ mr: 1 }} />
              {t('Back to Listing')}
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ mt: 5, mb: 2, maxWidth: '800px', mr: 'auto', ml: 'auto' }}>
            <Typography variant='h6' component='h6' sx={{ mt: 2, mb: 1 }} align='center'>
              {steps[activeStep].title}
            </Typography>
            {steps[activeStep].description ? (
              <Typography sx={{ mb: 2, height: '200' }} align='center'>
                {steps[activeStep].description}
              </Typography>
            ) : (
              ''
            )}
            <Box sx={{ maxWidth: '300px', mr: 'auto', ml: 'auto' }}>
              {steps[activeStep].input ? steps[activeStep].input : ''}
            </Box>
            {steps[activeStep].content ? steps[activeStep].content : ''}
            {steps[activeStep].terms ? (
              <Paper
                elevation={4}
                sx={{ maxWidth: '100%', height: '200px', overflowY: 'scroll' }}
              >
                <Box sx={{ padding: '5px 20px' }}>{steps[activeStep].terms}</Box>
              </Paper>
            ) : (
              ''
            )}
            <Box sx={{ mr: 'auto', ml: 'auto', mt: 5 }}>{errorMessage}</Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              pt: 2,
              maxWidth: '1000px',
              mr: 'auto',
              ml: 'auto',
            }}
          >
            <Grid container direction={largeScreen ? 'row' : 'column'}>
              <Grid item xs={12} sm={6}>
                {steps[activeStep].BackButton ? steps[activeStep].BackButton : ''}{' '}
                {largeScreen}
              </Grid>
              <Grid item xs={12} sm={6} align='right'>
                {steps[activeStep].NextButton}
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
