import { useQuery } from '@tanstack/react-query';
import { adminApi } from '@/lib/axios';
import { roundModulo10 } from '@/utils/math';

export const getBlockFilters = (statusId = [0]) => {  
  const requestPayload = {
    statusId,
  };

  return adminApi.post('/tradeInBlock/get-filters', requestPayload);
};

export const useFilters = (statusIds, config = {}) => {
  return useQuery({
    queryKey: ['block-filters'],
    queryFn: async () => {
      const data = await getBlockFilters(statusIds);     
      return {
        makeModels: data.make,
        colors: data.color,
        bodies: data.body,
        transmissions: data.transmission,
        fuels: data.fuel,
        drivetrains: data.drivetrain,
        sellerTypes: /* data.sellerType, */ [
          { id: 1, name: 'Dealer' },
          { id: 2, name: 'Private Seller' },
        ],
        yearRange: [data.yearMin, data.yearMax],
        priceRange: [
          roundModulo10(data.priceMin, Math.floor),
          roundModulo10(data.priceMax, Math.ceil),
        ],
        mileageRange: [
          roundModulo10(data.mileageMin, Math.floor),
          roundModulo10(data.mileageMax, Math.ceil),
        ],
      };
    },
    ...config,
  });
};
