import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const TermsAndConditionsFR = () => {
  return (
    <Box sx={{ p: 2, width: '100%', maxWidth: 900, margin: 'auto' }}>
      <Typography variant='h4' component='h4' sx={{ m: 4 }} align='center'>
        Termes et conditions
      </Typography>
      <div>
        <p>
          Bienvenue sur le site de SHOPITRADE.com, un site créé, propulsé et tenu par
          SHOPICAR INC. Vous êtes invité à accéder, naviguer et utiliser ce site selon les
          conditions légales suivantes (« Conditions légales d&apos;utilisation »).
        </p>
        <p>
          Le fait d&apos;accéder, de naviguer et d&apos;utiliser ce site signifie que vous
          acceptez ces Conditions Légales d&apos;Utilisation qui constituent un contrat
          exécutoire entre vous et SHOPITRADE.com, SHOPITRADE ou une société affiliée, le
          cas échéant. SHOPITRADE.com, SHOPITRADE et les sociétés affiliées vous
          encouragent à lire les Conditions Légales d&apos;Utilisation, dont notamment la
          politique de confidentialité de SHOPITRADE.com. Si vous n&apos;acceptez pas ces
          conditions, veuillez ne pas utiliser ce site. Si, à tout moment, ces conditions
          légales d&apos;utilisation ne vous sont plus acceptables, veuillez cesser
          d&apos;utiliser ce Site.
        </p>
        <p>
          <strong>Avis relatifs aux droits de propriété intellectuelle</strong>
        </p>
        <p>
          <strong>
            Copyright © SHOPITRADE.com, SHOPITRADE et les sociétés affiliées. Tous droits
            réservés.
          </strong>
        </p>
        <p>
          Le contenu de ce site, y compris, mais sans s&apos;y limiter, les logiciels, le
          texte, les données, le son, la programmation, les images, les graphiques et la
          conception du site, ainsi que le choix, l&apos;organisation et
          l&apos;arrangement de ce qui précède (collectivement désigné le « contenu »)
          sont protégés par des droits d&apos;auteur et autres droits de propriété
          intellectuelle, et sont détenus ou utilisés sous licence par SHOPITRADE.com,
          SHOPITRADE ou la société affiliée applicable.
        </p>
        <p>
          Les marques de commerce, marques de service, logos et autres marques utilisées
          sur ce site par SHOPITRADE.com, SHOPICARet les sociétés affiliées (les « Marques
          de commerce ») appartiennent à, ou sont contrôlées par SHOPITRADE.com,
          SHOPITRADE ou la société affiliée applicable. La liste des marques de commerce
          de SHOPITRADE.com est incorporée par référence à ces Conditions Légales
          d&apos;Utilisation dont elle fait partie intégrante. Il est interdit
          d&apos;utiliser ces marques de commerce sans l&apos;autorisation écrite
          préalable de SHOPITRADE.com, SHOPITRADE ou la société affiliée applicable et de
          les utiliser en relation avec un produit ou service de manière susceptible à
          créer la confusion chez les clients, ou de manière à dénigrer ou discréditer
          SHOPITRADE.com, SHOPITRADE ou la Société affiliée applicable. Toutes les marques
          de commerce, marques de service, logos et autres marques qui apparaissent sur ce
          site appartiennent à leurs propriétaires respectifs.
        </p>
        <p>
          Vous acceptez d&apos;adhérer aux avis de droits d&apos;auteur supplémentaires ou
          aux restrictions figurant dans le contenu.
        </p>
        <p>
          <strong>Restrictions d&apos;utilisation</strong>
        </p>
        <p>
          Vous pouvez télécharger et utiliser un nombre raisonnable de copies du contenu
          sur tout ordinateur uniquement pour votre propre usage personnel et
          noncommercial à condition de : (a) conserver intacts les avis de droit
          d&apos;auteur et de propriété intellectuelle, (b) ne pas modifier le contenu, et
          (c) ne pas utiliser le contenu pour suggérer une association avec
          SHOPITRADE.com, SHOPITRADE ou une société affiliée ou leurs produits, services
          ou marques respectifs. Vous n&apos;êtes pas autorisé à modifier, publier,
          transmettre, transférer, vendre, reproduire, télécharger, afficher, diffuser,
          créer des uvres dérivées, représenter ou exploiter de quelque façon que ce soit
          le contenu, en tout ou en partie, sauf autorisation expresse de SHOPITRADE.com,
          SHOPITRADE ou la société affiliée applicable dans ces Conditions Légales
          d&apos;Utilisation. Votre utilisation du contenu à des fins autres que celles
          expressément autorisées par la présente (y compris votre utilisation d&apos;une
          partie du contenu sur un autre site web ou un réseau d&apos;ordinateurs)
          constitue une violation des droits de propriété intellectuelle et des autres
          droits de propriété de SHOPITRADE.com, SHOPITRADE ou la société affiliée
          applicable et peut faire l&apos;objet de poursuites civiles ou pénales en vertu
          des lois applicables.
        </p>
        <p>
          <strong>Contenu canadien</strong>
        </p>
        <p>
          Les véhicules, produits et services décrits sur ce site contiennent uniquement
          des spécifications et caractéristiques canadiennes offertes exclusivement au
          Canada, aux résidents canadiens et uniquement là où la loi l&apos;autorise. Les
          prix et autres renseignements financiers publiés sur ce site sont en dollars
          canadiens, sauf indication contraire de SHOPITRADE.com, SHOPITRADE ou la société
          affiliée applicable, et SHOPITRADE.com, SHOPITRADE ou la société affiliée
          applicable sont autorisés à modifier les prix et les autres renseignements
          financiers, les occasions d&apos;emploi et tout autre renseignement fourni sur
          ce Site à tout moment et sans préavis.
        </p>
        <p>
          <strong>Liens vers des sites de tierces parties</strong>
        </p>
        <p>
          Ce site peut contenir des hyperliens donnant accès à des sites web de tierces
          parties. L&apos;utilisation de tels sites web à partir de ce site n est pas la
          responsabilité de SHOPITRADE.com. SHOPITRADE.com, SHOPITRADE ou la société
          affiliée ne peuvent assurer la disponibilité, la fiabilité et l&apos;exactitude
          du contenu d&apos;un site Web accessible à l&apos;aide d&apos;un hyperlien ou de
          tout lien contenu dans un site web accessible à l&apos;aide d&apos;un hyperlien.
          SHOPITRADE.com, SHOPITRADE ou la société affiliée mettent ces liens à votre
          disposition uniquement à titre pratique, et l&apos;inclusion de tout lien ne
          signifie pas et ne constitue pas le soutien de SHOPITRADE.com, SHOPITRADE ou de
          la société affiliée applicable pour le site web accessible à l&apos;aide
          d&apos;un hyperlien ou son contenu.
        </p>
        <p>
          <strong>Politique de confidentialité</strong>
        </p>
        <p>
          Pendant votre utilisation de ce site, vous pourrez volontairement soumettre des
          renseignements personnels à SHOPITRADE.com, SHOPITRADE ou une société affiliée.
          SHOPITRADE.com, SHOPITRADE et la société affiliée applicable vous encouragent à
          lire attentivement la politique de confidentialité de SHOPITRADE.com (la
          «Politique de confidentialité ») avant d&apos;utiliser ce site. En utilisant ce
          site, vous acceptez la Politique de Confidentialité qui décrit comment les
          renseignements personnels recueillis par le biais de ce Site sont traités par
          SHOPITRADE.com, SHOPITRADE et la société affiliée applicable. Si vous
          n&apos;êtes pas d&apos;accord avec la Politique de Confidentialité, ne nous
          communiquez pas de renseignements personnels.
        </p>
        <p>
          <strong>Modifications aux conditions légales d&apos;utilisation</strong>
        </p>
        <p>
          SHOPITRADE.com, SHOPITRADE et les sociétés affiliées se réservent le droit de
          modifier à tout moment ces conditions légales d&apos;utilisation, et vous
          acceptez de respecter ces modifications. Ces modifications prendront effet dès
          la remise d&apos;un préavis par SHOPITRADE.com, SHOPITRADE ou la société
          affiliée applicable sous quelque forme que ce soit, y compris, mais sans
          s&apos;y limiter, l&apos;affichage sur ce site. Vous acceptez de consulter
          régulièrement ces Conditions Légales d Utilisations affichées sur ce site et de
          vous tenir informé de telles modifications. L&apos;utilisation de ce site à la
          suite de telles modifications constitue le contrat que vous devez suivre et
          auquel vous êtes lié par les Conditions Légales d&apos;Utilisation modifiées.
          Si, à tout moment, ces Conditions Légales d&apos;Utilisation ne vous sont plus
          acceptables, vous devez cesser immédiatement d&apos;utiliser le site.
        </p>
        <p>
          <strong>Modification ou annulation de service</strong>
        </p>
        <p>
          SHOPITRADE.com, SHOPITRADE et les sociétés affiliées se réservent le droit, à
          leur entière discrétion, de limiter, suspendre ou annuler votre accès à ce site,
          en tout ou en partie, à tout moment et pour n&apos;importe quelle raison, sans
          préavis ni responsabilité à votre égard. SHOPITRADE.com, SHOPITRADE et les
          Sociétés affiliées peuvent, à tout moment, modifier, suspendre ou supprimer ce
          site, en tout ou en partie, sans préavis ni responsabilité à votre égard.
        </p>
        <p>
          <strong>Juridiction; loi applicable et arbitrage</strong>
        </p>
        <p>
          SHOPITRADE.com, SHOPITRADE et les sociétés affiliées ne font aucune déclaration
          que le contenu du site est approprié ou disponible pour une région en
          particulier. Vous êtes responsable du respect de la législation locale en
          vigueur dans votre lieu de résidence dans la mesure où une telle législation est
          applicable. Ces Conditions Légales d&apos;Utilisation et l&apos;utilisation de
          ce site seront régies et interprétées en conformité avec les lois en vigueur
          dans la province de Québec et avec les lois fédérales du Canada qui y sont
          applicables, sans référence à un choix de lois ou au principe du conflit des
          lois. La Convention des Nations-Unies sur les contrats de vente internationale
          de marchandises ne s&apos;applique pas aux présentes Conditions Légales
          d&apos;Utilisation ni aux communications et aux transactions effectuées avec ou
          par le biais de ce site.
        </p>
        <p>
          <strong>Généralités</strong>
        </p>
        <p>
          Au cas où une disposition des présentes Conditions Légales d&apos;Utilisation
          serait illégale, nulle ou inexécutable, la disposition incriminée sera jugée
          séparable des présentes Conditions Légales d&apos;Utilisation et n&apos;aura
          aucune incidence sur la validité et le caractère exécutoire des dispositions
          restantes. Les articles 1, 2, 4, 5, 6 et 7 survivront à la résiliation de ce
          contrat ainsi que toute autre disposition qui, de par ses modalités ou par sa
          nature, est censée lui survivre. Les présentes Conditions Légales
          d&apos;Utilisation constituent l&apos;accord intégral conclu entre vous et
          SHOPITRADE.com, SHOPITRADE et/ou les sociétés affiliées et elles remplacent
          toute entente préalable entre vous et SHOPITRADE.com, SHOPITRADE et/ou les
          sociétés affiliées concernant votre utilisation de ce site. The parties to this
          agreement declare that they have requested that this agreement and all ancillary
          documents be prepared in the french language. Les parties déclarent
          qu&apos;elles ont demandé que cette entente et tout document s&apos;y rapportant
          soient préparés en français.
        </p>
        <p>
          <strong>Langue</strong>
        </p>
        <p>
          The parties to this agreement declare that they have requested that this
          agreement and all ancillary documents be prepared in the french language. Les
          parties déclarent qu&apos;elles ont demandé que cette entente et tout document
          s&apos;y rapportant soient préparés en français.
        </p>
        <p>
          <strong>POLITIQUE DE CONFIDENTIALITÉ - SHOPITRADE.COM</strong>
        </p>
        <p>
          Devant la croissance des nouveaux outils de communication, il est absolument
          nécessaire de porter une attention toute particulière à la protection de la vie
          privée. C&apos;est pourquoi SHOPITRADE et SHOPITRADE.com s’engagent à respecter
          la confidentialité des renseignements personnels qu’ils pourraient collecter au
          cours de votre visite sur ce site.
        </p>
        <p>
          <strong>Formulaire et interactivité</strong>
        </p>
        <p>
          Les renseignements personnels que nous collectons sont recueillis au travers des
          formulaires et grâce à l&apos;interactivité établie entre vous et notre site
          web. Nous pouvons utiliser les renseignements personnels pour contacter une
          tierce partie afin de valider les renseignements personnels et financiers
          recueillis, procéder à une demande de crédit auprès d’institutions financières,
          vous proposer des offres promotionnelles, vous contacter ou compiler des
          statistiques.
        </p>
        <p>
          <strong>Fichiers journaux et témoins</strong>
        </p>
        <p>
          Nous recueillons certaines informations par le biais de fichiers journaux (log
          file) et de fichiers témoins (cookies). Il s&apos;agit principalement des
          informations suivantes : Adresse IP, système d&apos;exploitation, pages visitées
          et requêtes, et heure et jour de connexion. Les renseignements transmis le sont
          en qualité de statistiques exclusivement et ne dévoilent en aucun cas votre
          identité. Aucune information permettant de vous identifier n’est recueillie par
          ces fichiers journaux et témoins.
        </p>
        <p>
          <strong>Partage de renseignements personnels</strong>
        </p>
        <p>
          Avec votre autorisation, SHOPITRADE.com vous enverra de l&apos;information
          pertinente sur ses promotions, ses partenaires ou de tierces parties.
          L&apos;information concernera surtout les nouveaux produits et services de ces
          partenaires. Nous appliquerons les moyens nécessaires pour vous assurer que vous
          ne recevrez aucunement du courrier indésirable. Si vous désirez que vos
          renseignements personnels ne soient pas communiqués à des tiers, il vous est
          possible de vous y opposer à tout moment ultérieurement.
        </p>
        <p>
          <strong>Sécurité</strong>
        </p>
        <p>
          Les renseignements personnels que nous collectons sont préservés dans un
          environnement sécurisé. Les individus à notre service sont tenus de respecter la
          confidentialité de vos informations personnelles. Pour assurer la sécurité de
          vos renseignements personnels, nous avons recours aux mesures suivantes :
          protocole SSL (Secure Sockets Layer), gestion des accès, identifiant / mot de
          passe, sauvegarde informatique et pare-feu (Firewalls). Nous nous engageons
          également à maintenir un haut degré de confidentialité en intégrant les toutes
          dernières innovations technologiques permettant d&apos;assurer la
          confidentialité de vos transactions.
        </p>
        <p>
          <strong>Liens externes</strong>
        </p>
        <p>
          Ce site Web contient des liens vers d&apos;autres sites. SHOPITRADE et
          SHOPITRADE.com ne sont pas responsable des pratiques de protection des
          renseignements personnels ni du contenu de ces sites Web. Pour vous assurer de
          la protection de vos renseignements personnels, nous vous recommandons de
          consulter la politique de confidentialité de tout autre site auquel vous accédez
          au moyen d&apos;un lien sur le site Web de SHOPITRADE.com.
        </p>
        <p>
          <strong>Accès au site Web</strong>
        </p>
        <p>
          Ce site Web est hébergé sur des serveurs situés au Canada. Par conséquent, votre
          connexion se fera par l&apos;entremise de serveurs situés au Canada. Tous les
          renseignements personnels que vous fournissez durant votre visite seront traités
          et conservés sur notre serveur Web et sur nos autres systèmes internes situés au
          Canada.
        </p>
        <p>
          <strong>Mot de passe</strong>
        </p>
        <p>
          Le dossier de données personnelles créé au moment de votre inscription à
          SHOPITRADE.com est accessible uniquement au moyen du mot de passe unique qui y
          est associé. Pour protéger l&apos;intégrité des renseignements personnels
          contenus dans ce dossier, vous ne devez pas divulguer ou révéler autrement votre
          mot de passe à des tiers.
        </p>
        <p>
          <strong>Forums publics</strong>
        </p>
        <p>
          Le site Web de SHOPITRADE.com peut mettre à la disposition de ses utilisateurs
          des blogs, des forums, des babillards et/ou d&apos;autres groupes de discussion.
          Veuillez noter que, à moins d&apos;indication contraire, tout renseignement
          divulgué dans ces espaces devient un renseignement public. Par conséquent, vous
          devez faire preuve de vigilance lorsque vous choisissez de divulguer des
          renseignements dans ces espaces.
        </p>
        <p>
          <strong>Modifications à la présente politique de confidentialité</strong>
        </p>
        <p>
          Nous nous réservons le droit de modifier et de mettre à jour en tout temps la
          présente politique de confidentialité, lorsque nous le jugeons approprié. Nous
          vous invitons à consulter périodiquement notre politique de confidentialité pour
          vous assurer d&apos;être au courant des modifications que nous pourrions avoir
          apportées.
        </p>
        <p>
          Veuillez communiquer avec nous pour toute question concernant la présente
          politique de confidentialité ou les pratiques de ce site Web.
        </p>
        <p>
          <strong>SHOPITRADE et SHOPITRADE.com</strong>
        </p>
        <p>
          <strong>SHOPITRADE © Tous droits réservés</strong>
        </p>
      </div>
    </Box>
  );
};
