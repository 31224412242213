import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { getUnitFormatter, getPriceFormatter } from '@/utils/numberFormats';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const CarDetails = (props) => {
  const { data } = props;
  return (
    <div>
      <Box width='100%' position='relative'>
        <Card
          variant='outlined'
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={3}>
              <CardMedia
                component='img'
                sx={{ width: '100%', maxHeight: '200px' }}
                image={
                  data.image
                    ? data.image
                    : 'https://s3.amazonaws.com/shopicar-chrome-data-images-main/0.jpg'
                }
                alt='Main photo'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8} lg={9}>
                    <Stack spacing={2}>
                      <Box>
                        <Typography component='h5' variant='h5' fontWeight='500'>
                          <Box
                            component='span'
                            px={0.5}
                            sx={{
                              borderRadius: '8px',
                              border: '2px solid',
                              backgroundColor: 'secondary',
                              fontSize: '23px',
                              marginRight: '10px',
                            }}
                          >
                            {data.year}
                          </Box>
                          {[data.make, data.model].join(' ')}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          color='text.secondary'
                          component='div'
                        >
                          {[
                            getUnitFormatter('kilometer').format(data.mileage),
                            data.transmissionType,
                            data.trim,
                            data.fuelType,
                          ]
                            .filter((v) => v !== '')
                            .join(' | ')}
                        </Typography>
                      </Box>
                      {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {data.tags.map((tag) => (
                          <Chip
                            variant={tag.variant}
                            key={tag.text}
                            label={tag.text}
                            sx={tag.style}
                          />
                        ))}
                      </Box> */}
                      <Typography
                        margin='normal'
                        component='div'
                        sx={{ display: 'flex' }}
                      >
                        <LocationOnIcon color='secondary' /> {data.city}, QC
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Stack spacing={1}>
                      <Box>
                        <Typography
                          component='div'
                          variant='h4'
                          color='primary'
                          fontWeight='500'
                          align='right'
                        >
                          {getPriceFormatter('USD').format(data.price)}
                        </Typography>
                        <Typography
                          component='div'
                          variant='h6'
                          fontWeight='500'
                          align='right'
                        >
                          {data.offers} offer{data.offers > 1 ? 's' : ''}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </div>
  );
};
