import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { VehicleList } from '../../../components/live/VehicleList';
import { Filters } from '../../../components/Filters';
import { useFilters } from '../../../api/getFilters';
import Grid from '@mui/material/Grid';

export const VehiclesLive = () => {
  const [filters, setFilters] = React.useState(null);
  const [filtersId, setFiltersId] = React.useState(Date.now());
  const section = 'live';
  const filtersQuery = useFilters([2], {queryKey: ['block-filters', section]});
  
  if (filtersQuery.isLoading) {
    return (
      <Box justifyContent='center' display='flex'>
        <CircularProgress size={100} />
      </Box>
    );
  }
  return (
    <Container maxWidth='xl'>
    <Box py={4} width='100%' display='flex'>
      <Grid container spacing={1}>
        {filtersQuery.data.makeModels && (
          <Grid item xs={12} sm={3}>
            <Filters 
              key={filtersId}
              filterValues={filtersQuery.data}
              setFilters={setFilters}
              setFiltersId={setFiltersId}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={9}>
          <VehicleList
            filters={{
              ...filters,
              LanguageId: 1 /* hardcoded => TODO */,
              StatusId: [2] /* (Live) (Expired) */,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  </Container>
  );
};

// [
//   {
//       "Id": 1,
//       "Name": "Comming soon"
//   },
//   {
//       "Id": 4,
//       "Name": "Expired"
//   },
//   {
//       "Id": 2,
//       "Name": "Live"
//   },
//   {
//       "Id": 8,
//       "Name": "Out of market"
//   },
//   {
//       "Id": 5,
//       "Name": "Pending"
//   },
//   {
//       "Id": 6,
//       "Name": "Pending offer"
//   },
//   {
//       "Id": 7,
//       "Name": "Pending sold"
//   },
//   {
//       "Id": 3,
//       "Name": "Sold"
//   }
// ]
