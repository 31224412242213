import * as React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useVehicle } from '../../../api/getVehicle';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { VehicleImages } from '../../../components/VehilcleDetails/VehicleImages';
import { VehicleBasicInformation } from '../../../components/VehilcleDetails/VehicleBasicInformation';
import { VehicleConditions } from '../../../components/VehilcleDetails/VehicleConditions';
import { VehicleNotes } from '../../../components/VehilcleDetails/VehicleNotes';
import { VehicleActionSideBarLive } from '../../../components/VehilcleDetails/ActionSideBar/VehicleActionSideBarLive';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useAuth } from '@/contexts/auth';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';

export const VehicleDetailsLive = () => {
  let { id } = useParams();
  const { user } = useAuth();
  const { t } = useTranslation();

  // const PrettyJson = (data) => {
  //   return <pre>{JSON.stringify(data, null, 2)}</pre>;
  // };

  const vehicleQuery = useVehicle({
    data: { id: id },
  });
  if (vehicleQuery.isLoading) {
    return (
      <Box sx={{ p: 20, maxWidth: 1000, mx: 'auto' }} align='center'>
        <CircularProgress size={100} />
      </Box>
    );
  }
  const vehicle = vehicleQuery?.data;
  const showDetails = vehicle && vehicle?.statusId === 2;

  const VehicleNotFound = () => (
    <Box sx={{ p: 5, pt: 15, maxWidth: 1000, mx: 'auto' }} align='center'>
      <h2>{t('VehicleDetailsNotFoundMessage_1')}</h2>
      <Button
        variant='contained'
        to='/live'
        component={Link}
        endIcon={<ArrowForwardIcon />}
      >
        {t('Live Inventory')}
      </Button>
    </Box>
  );

  const VehicleDetails = () => {
    return (
      <Box sx={{ p: 1, flexGrow: 1, maxWidth: 1500, mx: 'auto' }} m={2}>
        <Grid container spacing={1}>
          <Grid item container xs={12} md={9} lg={9}>
            <Paper sx={{ flexGrow: 1, position: 'relative' }}>
              <VehicleImages images={vehicle.images} imagesCustom={vehicle.imagesCustom} />
              <VehicleBasicInformation vehicle={vehicle} />
              <Box
                component='div'
                px={1}
                sx={{
                  color: '#fff',
                  position: 'absolute',
                  top: '8px',
                  left: '8px',
                  fontSize: '30px',
                  cursor: 'pointer',
                  textShadow: '2px 2px black',
                }}
              >
                <FullscreenIcon fontSize='30px' />
              </Box>
            </Paper>
          </Grid>
          <Grid item container xs={12} md={3} lg={3}>
            <VehicleActionSideBarLive vehicle={vehicle} />
          </Grid>
        </Grid>
        <Box my={2}>
          <VehicleConditions conditions={vehicle.conditions} />
        </Box>
        <Box>
          <VehicleNotes languageId={user.Language_ID} notes={vehicle.notes} />
        </Box>
        {/* <Paper>
    <PrettyJson data={vehicle} />
  </Paper> */}
      </Box>
    );
  };

  return showDetails ? <VehicleDetails /> : <VehicleNotFound />;
};
