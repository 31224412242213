import { useQuery } from '@tanstack/react-query';
import { adminApi } from '@/lib/axios';

export function postBuyNow(data) {
  return adminApi.post('/tradeInBlock/buy-now', data);
}

export const useBuyNow = ({ data, config }) => {
  return useQuery({
    queryKey: ['Make Offer', data],
    queryFn: () => postBuyNow(data),
    ...config,
  });
};
