import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

export const MultiSelectionChipList = ({
  chips = [],
  selectedChips = [],
  setSelectedChips
}) => {
  const shouldRenderAllAndNoneButtonsAsTwoButton =
    selectedChips.length > 0 && selectedChips.length < chips.length;

  const shouldRenderAllAndNoneButtonsAsOneButton =
    !shouldRenderAllAndNoneButtonsAsTwoButton;

  const isAllChipsSelected = selectedChips.length === chips.length;

  const handleChipClick = (chipId) => () => {
    const newList = selectedChips.slice();
    const index = newList.indexOf(chipId);
    if (index > -1) {
      newList.splice(index, 1);
    } else {
      newList.push(chipId);
    }
    setSelectedChips(newList);
  };

  const handleClickAll = () => {
    setSelectedChips(chips.map((chip) => chip.id));
  };

  const handleClickNone = () => {
    setSelectedChips([]);
  };

  return (
    <Grid container spacing={1}>
      {shouldRenderAllAndNoneButtonsAsTwoButton && (
        <Grid container spacing={1} item xs={12}>
          <Grid item xs={6}>
            <Chip
              key='all'
              label='All'
              variant='outlined'
              onClick={handleClickAll}
              sx={{ width: '100%', borderRadius: '8px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Chip
              key='none'
              label='None'
              variant='outlined'
              onClick={handleClickNone}
              sx={{ width: '100%', borderRadius: '8px' }}
            />
          </Grid>
        </Grid>
      )}
      {shouldRenderAllAndNoneButtonsAsOneButton && (
        <Grid item xs={12}>
          <Chip
            key='all'
            label={isAllChipsSelected ? 'None' : 'All'}
            variant='outlined'
            onClick={isAllChipsSelected ? handleClickNone : handleClickAll}
            sx={{ width: '100%', borderRadius: '8px' }}
          />
        </Grid>
      )}
      {chips.map((chip) => (
        <Grid key={`grid-${chip.name}`} item xs={6}>
          <Chip
            key={chip.name}
            label={chip.name}
            color='primary'
            variant={selectedChips.indexOf(chip.id) > -1 ? 'filled' : 'outlined'}
            onClick={handleChipClick(chip.id)}
            sx={{ width: '100%' }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

