import { useQuery } from '@tanstack/react-query';
import { adminApi } from '@/lib/axios';

export function getAllTradeInBlock(data) {  
  return adminApi.post('/tradeInBlock/get-all-trade-in-block', data);
}

export const useVehicles = ({ data, config }) => {    
  return useQuery({
    queryKey: ['block-vehicles', data],
    queryFn: () => getAllTradeInBlock(data),
    ...config,
  });
};
