import { useLocation } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '@/contexts/auth';

const ProtectedRoutes = () => {
  const { user } = useAuth();
  const location = useLocation();
  return user ? <Outlet /> : <Navigate to='/login' replace state={{ from: location }} />;
};

export default ProtectedRoutes;
