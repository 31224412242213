import * as React from 'react';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@/contexts/auth';
import {
  AsYouType,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';

export const LoginForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = React.useState(
    state ? state.phoneNumber ?? '' : ''
  );
   
  const [invalidPhoneSubmitted, setInvalidPhoneSubmitted] = React.useState(false);
  const { requestCodeMutation } = useAuth();
  const asYouType = new AsYouType('CA');
  const isError =
    !(phoneNumber === '') &&
    !(validatePhoneNumberLength(phoneNumber, 'CA') === 'TOO_SHORT') &&
    !isValidPhoneNumber(phoneNumber, 'CA');

  const handlePhoneChange = (event) => {
    const formated = asYouType.input(event.target.value);
    if (isValidPhoneNumber(formated, 'CA')) setInvalidPhoneSubmitted(false);
    setPhoneNumber(formated.replace(/[()]/g, ''));
    asYouType.reset();
  };

  const onSuccess = () =>
      navigate('sms-code', { state: { phoneNumber, from: state?.from } });

    const handleContinue = () => {
        
        if (isValidPhoneNumber(phoneNumber, 'CA')) {            
      return requestCodeMutation.mutate({ phoneNumber }, { onSuccess });
    }      
    setInvalidPhoneSubmitted(true);
  };

  return (
    <>
      {requestCodeMutation.isError && (
        <Alert severity='error' sx={{ textAlign: 'left' }}>
          We cannot find an account with that phone
        </Alert>
      )}
      <TextField
        required
        type='tel'
        id='phone-number'
        label='Phone Number'
        value={phoneNumber}
        onChange={handlePhoneChange}
        error={invalidPhoneSubmitted || isError}
        helperText={invalidPhoneSubmitted || isError ? 'Invalid phone number' : ''}
      />
      <LoadingButton
        variant='contained'
        size='large'
        loading={requestCodeMutation.isLoading}
        onClick={handleContinue}
      >
        Continue
      </LoadingButton>
    </>
  );
};
