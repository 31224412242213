import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';
import storage from '@/utils/storage';
import { userApi } from '@/lib/axios';

const wrapperStyle = {
  bottom: 0,
  right: 0,
  left: 0,
  color: '#939b9f',
  padding: 2,
  textAlign: 'center',
};

export const Footer = () => {
  const { t, i18n } = useTranslation();  
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarType, setSnackbarType] = React.useState('success'); 

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

  const handleTokenDeletion = async () => {
    
    try {      
        await userApi.post('/user/delete-token', {});
        storage.clearToken();
        const message = i18n.language === 'en' ? 'Logout successful' : 'Déconnexion réussie';
        setSnackbarMessage(message);
        setSnackbarType('success');
        setOpenSnackbar(true);
        window.location.assign(window.location.origin);
    }
    catch (error) {
        setSnackbarMessage('Error occurred.');
        setSnackbarType('error');
        setOpenSnackbar(true);
        }
    };

  return (
    <Box component='footer' sx={wrapperStyle}>
      <Stack alignItems='center' spacing={1}>
        <Typography>Shopicar Inc. - {t('All rights reserved')}</Typography>
        <Typography>{t('ShopicarConsentMessage')}</Typography>
        <Stack direction='row' spacing={1}>
          {i18n.language == 'en' ? (
            <>
              <Link
                to='/terms-and-conditions'
                style={{ textDecoration: 'none' }}
              >
                <Typography color='primary'>{t('Terms and conditions')}</Typography>
              </Link>
              <Divider orientation='vertical' flexItem color='#939b9f' />
              <Link to='/privacy-policy' style={{ textDecoration: 'none' }}>
                <Typography color='primary'>{t('Privacy policy')}</Typography>
              </Link>
              <Divider orientation='vertical' flexItem color='#939b9f' />
              <Typography
                component="span"
                color='primary'
                style={{cursor: 'pointer' }}
                onClick={handleTokenDeletion}
              >
                {t('Logout')}
              </Typography>
            </>
          ) : (
            <>
              <Link
                to='/termes-et-conditions'
                style={{ textDecoration: 'none' }}
              >
                <Typography color='primary'>{t('Terms and conditions')}</Typography>
              </Link>
              <Divider orientation='vertical' flexItem color='#939b9f' />
              <Link to='/politique-de-confidentialité' style={{ textDecoration: 'none' }}>
                <Typography color='primary'>{t('Politique de confidentialité')}</Typography>
              </Link>
              <Divider orientation='vertical' flexItem color='#939b9f' />
              <Typography
                component="span"
                color='primary'
                style={{cursor: 'pointer' }}
                onClick={handleTokenDeletion}
              >
                {t('Déconnexion')}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbarType} variant="filled">
                  {snackbarMessage}
              </Alert>
          </Snackbar>
    </Box>
  );
};
