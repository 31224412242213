import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import BuyNowSteps from './BuyNowSteps';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

export const BuyNowModal = (props) => {
  const { open, onClose, vehicleData } = props;

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
  };

  return (
    <Modal
      key={vehicleData.id}
      open={open}
      onClose={onClose}
      aria-labelledby='keep-mounted-modal-title'
      aria-describedby='keep-mounted-modal-description'
    >
      <Box sx={modalStyle}>
        <Box sx={{ width: '100%', display: 'inline-block' }}>
          <Button
            sx={{ float: 'right', p: 3,  }}
            onClick={onClose}
          >
            <CloseIcon sx={{ mr: 0 }} /> Close
          </Button>
        </Box>
        <Container>
          <BuyNowSteps key={vehicleData.id} vehicleData={vehicleData} onClose={onClose} />
        </Container>
      </Box>
    </Modal>
  );
};
