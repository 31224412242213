import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { VehicleCard } from './VehicleCard';
import { useVehicles } from '../../api/getVehicles';
import { useTranslation } from 'react-i18next';

export const VehicleList = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { filters } = props;
  const vehiclesQuery = useVehicles({
    data: { ...filters, Page: page, PageSize: rowsPerPage },
  });
  const vehicles = vehiclesQuery?.data?.allTradeInOnTheBlock;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { t } = useTranslation();

  if (vehiclesQuery.isLoading) {
    return (
      <Box mx='auto' my='auto'>
        <CircularProgress size={100} />
      </Box>
    );
  }

  if (!vehicles.length) {
    return <Typography variant='h5'>{t('0 results matching your filters')}</Typography>;
  }

  return (
    <Stack spacing={2} width='100%'>
      {vehicles.map((item) => (
        <VehicleCard key={item.id} data={item} />
      ))}
      <TablePagination
        component='div'
        color='primary'
        count={vehicles.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
};
