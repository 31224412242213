import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const Timer = ({ deadline = new Date().toString() }) => {
  const parsedDeadline = useMemo(() => Date.parse(deadline), [deadline]);
  const [time, setTime] = useState(parsedDeadline - Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(parsedDeadline - Date.now()), 1000);

    return () => clearInterval(interval);
  });

  const { t } = useTranslation();

  const ExpiredDate = {
    days: {
      label: time / DAY >= 2 ? t('days') : t('day'),
      value: Math.floor(time / DAY),
    },
    h: {
      label: 'h',
      value: Math.floor((time / HOUR) % 24),
    },
    min: {
      label: 'min',
      value: Math.floor((time / MINUTE) % 60),
    },
    s: {
      label: 's',
      value: Math.floor((time / SECOND) % 60),
    },
  };

  return (
    <span>
      {Object.entries(ExpiredDate).map(([label, value]) =>
        value.value > 0 ? (
          <span key={label}>
            <span>{value.value}</span>
            <span>{value.label} </span>
          </span>
        ) : (
          ''
        )
      )}
    </span>
  );
};
