import * as React from 'react';

export default function TermsAndConditionsFR() {
  return (
    <div id='description'>
      <div>
        <p>
          <strong>Types d&apos;actions:</strong>
        </p>
        <p>
          <u>1.1. Prix &apos;Achetez maintenant&apos;:</u> Le prix &apos;Achetez
          maintenant&apos; représente le prix demandé par le vendeur et constitue une
          offre contraignante de vente du véhicule. En sélectionnant l&apos;option Achetez
          maintenant, l&apos;Acheteur accepte l&apos;offre et s&apos;engage à acheter le
          véhicule au prix spécifié. L&apos;Acheteur doit immédiatement soumettre le
          paiement pour sécuriser la transaction et le véhicule sera marqué comme &apos;En
          attente de vente&apos;.
        </p>
        <p>
          <u>1.2. En attente de vente:</u> Le véhicule restera en statut &apos;En attente
          de vente&apos; jusqu&apos;à ce que la transaction soit terminée. La transaction
          ne sera considérée comme terminée que lorsque le vendeur confirmera la vente et
          que l&apos;Acheteur soumettra le paiement. Si la transaction n&apos;est pas
          terminée dans le délai spécifié par Shopitrade, le véhicule sera remis en vente.
        </p>
        <p>
          <u>1.3. Offres inférieures au prix &apos;Achetez maintenant&apos;:</u> Les
          enchérisseurs peuvent soumettre une seule offre pour acheter un véhicule à un
          prix inférieur au prix &apos;Achetez maintenant&apos;. Les offres seront
          affichées dans la section &apos;Mes offres&apos; du site. Si un vendeur accepte
          une offre, le véhicule sera marqué comme &apos;En attente de vente&apos;, et
          l&apos;Acheteur doit soumettre le paiement pour terminer la transaction.
        </p>
        <p>
          <u>1.4. Arbitrage:</u> Tout litige découlant de transactions impliquant des
          véhicules vendus par l&apos;intermédiaire de Shopitrade sera réglé conformément
          aux procédures d&apos;arbitrage établies par Shopicar Inc. L&apos;arbitrage aura
          lieu dans la province de Québec, et les lois du Québec régiront
          l&apos;arbitrage.
        </p>
        <p>
          <strong>Loi applicable et juridiction:</strong>
        </p>
        <p>
          Ces termes et conditions seront régis par et interprétés conformément aux lois
          de la province de Québec, sans donner effet à ses dispositions relatives aux
          conflits de lois. Tout litige découlant de ou lié à ces termes et conditions
          sera porté exclusivement devant les tribunaux de la province de Québec.
        </p>
        <p>
          <strong>Paiement:</strong>
        </p>
        <p>
          Le paiement de tous les véhicules achetés par l&apos;intermédiaire de Shopitrade
          doit être effectué intégralement avant que le véhicule ne puisse être remis. Le
          paiement doit être effectué via les méthodes de paiement fournies sur notre site
          web. Nous nous réservons le droit d&apos;annuler toute transaction si nous
          soupçonnons une fraude ou toute autre activité illégale.
        </p>
        <p>
          <strong>Informations sur le véhicule:</strong>
        </p>
        <p>
          Nous fournissons des informations sur les véhicules au mieux de notre
          connaissance, mais nous ne garantissons pas leur exactitude. Nous encourageons
          les Acheteurs à inspecter le véhicule avant l&apos;achat.
        </p>
        <p>
          <strong>Garantie:</strong>
        </p>
        <p>
          Nous n&apos;offrons aucune garantie pour les véhicules vendus sur notre site
          web.
        </p>
        <p>
          <strong>Politique de retour:</strong>
        </p>
        <p>
          Nous n&apos;acceptons aucun retour sur les véhicules vendus sur notre site web.
        </p>
        <p>
          <strong>Propriété intellectuelle:</strong>
        </p>
        <p>
          Tous les contenus présents sur le site web de Shopitrade, y compris mais sans
          s&apos;y limiter, le texte, les images, les marques de commerce, les logos et
          les vidéos, sont la propriété exclusive de Shopicar Inc. ou de ses fournisseurs
          de contenu, et sont protégés par les lois canadiennes et internationales sur les
          droits d&apos;auteur et les marques de commerce. Les utilisateurs ne peuvent pas
          copier, reproduire, distribuer, publier, afficher, transmettre, modifier ou
          créer des œuvres dérivées de tout contenu apparaissant sur le site web de
          Shopitrade sans le consentement écrit préalable de Shopicar Inc.
        </p>
        <p>
          <strong>Modification des termes et conditions:</strong>
        </p>
        <p>
          Shopicar Inc. se réserve le droit de modifier ces termes et conditions à tout
          moment. Les utilisateurs sont encouragés à consulter régulièrement ces termes et
          conditions pour être informés des changements.
        </p>
        <p>
          <strong>Résiliation:</strong>
        </p>
        <p>
          Shopicar Inc. se réserve le droit de résilier l&apos;accès d&apos;un utilisateur
          à son site web à tout moment, sans préavis et sans donner de raisons.
        </p>
        <p>
          Nous vous remercions de votre confiance et espérons que votre expérience avec
          Shopitrade sera agréable et fructueuse. Si vous avez des questions ou des
          préoccupations, n&apos;hésitez pas à nous contacter.
        </p>
        <p></p>
      </div>
    </div>
  );
}
