import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const PrivacyPolicyFR = () => {
  return (
    <Box sx={{ p: 2, width: '100%', maxWidth: 900, margin: 'auto' }}>
      <Typography variant='h4' component='h4' sx={{ m: 4 }} align='center'>
        Politique de confidentialité
      </Typography>
      <div>
        <p>
          Cette politique de confidentialité divulgue les pratiques de confidentialité de
          SHOPITRADE.com. Cette politique de confidentialité s&lsquo;applique uniquement
          aux informations collectées par ce site Web. Il vous informera de ce qui suit :
        </p>
        <ul>
          <li>
            Quelles informations personnellement identifiables sont collectées auprès de
            vous via le site Web, comment elles sont utilisées et avec qui elles peuvent
            être partagées.
          </li>
          <li>
            Quels sont les choix qui s&lsquo;offrent à vous concernant l&lsquo;utilisation
            de vos données. Les procédures de sécurité en place pour protéger
            l&lsquo;utilisation abusive de vos informations.
          </li>
          <li>Comment vous pouvez corriger toute inexactitude dans les informations.</li>
        </ul>
        <br />
        <p>
          <strong>Collecte, utilisation et partage d&lsquo;informations</strong>
        </p>
        <p>
          Nous sommes les seuls propriétaires des informations recueillies sur ce site.
          Nous n&lsquo;avons accès/collectons que les informations que vous nous
          fournissez volontairement par e-mail ou par tout autre contact direct de votre
          part. Nous ne vendrons ni ne louerons ces informations à personne.
        </p>
        <p>
          Nous utiliserons vos informations pour vous répondre, concernant la raison pour
          laquelle vous nous avez contactés. Nous ne partagerons pas vos informations avec
          des tiers en dehors de notre organisation, sauf si nécessaire pour répondre à
          votre demande, par ex. pour expédier une commande.
        </p>
        <p>
          À moins que vous ne nous demandiez de ne pas le faire, nous pourrons vous
          contacter par e-mail à l&lsquo;avenir pour vous informer des offres spéciales,
          des nouveaux produits ou services, ou des modifications apportées à cette
          politique de confidentialité.
        </p>
        <br />
        <p>
          <strong>Votre accès aux informations et votre contrôle sur celles-ci</strong>
        </p>
        <p>
          Vous pouvez à tout moment vous retirer de tout contact futur de notre part. Vous
          pouvez faire ce qui suit à tout moment en nous contactant via l&lsquo;adresse
          e-mail ou le numéro de téléphone indiqué sur notre site Web:
        </p>
        <ul>
          <li>Voir quelles données nous avons sur vous, le cas échéant.</li>
          <li>Modifier/corriger toutes les données que nous possédons à votre sujet.</li>
          <li>Demandez-nous de supprimer toutes les données que nous avons sur vous.</li>
          <li>
            Exprimez toute préoccupation que vous avez concernant notre utilisation de vos
            données.
          </li>
        </ul>
        <br />
        <p>
          <strong>Sécurité</strong>
        </p>
        <p>
          Nous prenons des précautions pour protéger vos informations. Lorsque vous
          soumettez des informations sensibles via le site Web, vos informations sont
          protégées à la fois en ligne et hors ligne.
        </p>
        <p>
          Partout où nous recueillons des informations sensibles (telles que les données
          de carte de crédit), ces informations sont cryptées et nous sont transmises de
          manière sécurisée. Vous pouvez le vérifier en recherchant une icône de cadenas
          fermé au bas de votre navigateur Web ou en recherchant &ldquo;https&ldquo; au
          début de l&lsquo;adresse de la page Web.
        </p>
        <p>
          Alors que nous utilisons le cryptage pour protéger les informations sensibles
          transmises en ligne, nous protégeons également vos informations hors ligne.
          Seuls les employés qui ont besoin des informations pour effectuer un travail
          spécifique (par exemple, la facturation ou le service client) ont accès aux
          informations personnellement identifiables. Les ordinateurs/serveurs dans
          lesquels nous stockons des informations personnellement identifiables sont
          conservés dans un environnement sécurisé.
        </p>
        <br />
        <p>
          <strong>Mises à jour</strong>
        </p>
        <p>
          Alors que nous utilisons le cryptage pour protéger les informations sensibles
          transmises en ligne, nous protégeons également vos informations hors ligne.
          Seuls les employés qui ont besoin des informations pour effectuer un travail
          spécifique (par exemple, la facturation ou le service client) ont accès aux
          informations personnellement identifiables. Les ordinateurs/serveurs dans
          lesquels nous stockons des informations personnellement identifiables sont
          conservés dans un environnement sécurisé.
        </p>
        <p>
          <strong>
            Si vous pensez que nous ne respectons pas cette politique de confidentialité,
            vous devez nous contacter immédiatement par téléphone au 1 800-390-9929 ou par
            e-mail.
          </strong>
        </p>
        <br />
      </div>
    </Box>
  );
};
