export const getUnitFormatter = (unit, config = {}) => {
  const { local = 'en-US', unitDisplay = 'short' } = config;
  return new Intl.NumberFormat(local, {
    unit,
    unitDisplay,
    style: 'unit',
    ...config,
  });
};

export const getPriceFormatter = (currency, config = {}) => {
  const { local = 'en-US', maximumFractionDigits = 0 } = config;
  return new Intl.NumberFormat(local, {
    currency,
    maximumFractionDigits,
    style: 'currency',
    ...config,
  });
};
