import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Timer } from '../../live/Utils/Timer';
import Moment from 'react-moment';

export const VehicleActionSideBarOffers = (props) => {
  const { vehicle } = props;

  //const dateLive = '1/25/2023 1:00:00 PM';
  const dateLive = vehicle.dateLive.replace('T', ' ') + ' UTC';
  const duration = vehicle.duration;
  const addedToBlockDateParsed = Date.parse(dateLive);
  const deadline = addedToBlockDateParsed + duration * 60000;
  const isExpired = Date.now() > deadline;

  const priceFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  return (
    <Paper sx={{ flexGrow: 1, padding: 2 }}>
      <Stack spacing={2} width='100%'>
        <Typography
          component='div'
          variant='p'
          fontWeight='500'
          fontSize='23px'
          align='center'
          sx={{
            mt: 0,
            color: '#fff',
            borderRadius: '4px',
            backgroundColor: '#52a9dd',
            pr: 3,
            pl: 3,
            mr: 'auto',
            ml: 'auto',
          }}
        >
          {vehicle.offerStatus}
        </Typography>
        <Divider />
        {vehicle.showPrice && (
          <>
            <Typography
              component='span'
              variant='h4'
              color='primary'
              fontWeight='500'
              align='center'
            >
              {priceFormatter.format(vehicle.price)}
            </Typography>
          </>
        )}
        <Typography component='span' variant='h5' fontWeight='500' align='center'>
          {vehicle.offers} offer{vehicle.offers > 1 ? 's' : ''}
        </Typography>
        <Typography component='span' variant='span' fontWeight='500' align='center'>
          {isExpired ? 'Expired' : <Timer deadline={new Date(deadline)} />}
        </Typography>
        <Divider />
        <Box>
          <Typography
            component='div'
            variant='p'
            fontSize='20px'
            align='center'
            color='#555'
          >
            Your offer
          </Typography>
          <Typography
            component='div'
            variant='p'
            fontWeight='500'
            fontSize='28px'
            align='center'
            sx={{ m: 0 }}
          >
            {priceFormatter.format(vehicle.yourOffer)}
          </Typography>
          <Typography
            component='div'
            variant='p'
            fontSize='16px'
            fontWeight='500'
            align='center'
            color='#555'
          >
            {vehicle.offerMadeByUser}
          </Typography>
          <Typography
            component='div'
            variant='p'
            fontSize='14px'
            align='center'
            color='#555'
          >
            <Moment format='LLL'>{vehicle.offerDate + ' UTC'}</Moment>
          </Typography>
        </Box>
        <Divider />
      </Stack>
    </Paper>
  );
};
