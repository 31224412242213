import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const NotFound = () => {
  return (
    <Box sx={{ p: 2, width: '100%', maxWidth: 700, margin: 'auto' }} align='center'>
      <Typography variant='h2' component='h2' sx={{ pt: 10 }}>
        404
      </Typography>
    </Box>
  );
};
