import { useQuery } from '@tanstack/react-query';
import { adminApi } from '@/lib/axios';

export function getTradeInBlock(data) {
  return adminApi.get('/tradeInBlock/get-details', { params: data});
}

export const useVehicle = ({ data, config }) => {
  return useQuery({
    queryKey: ['block-vehicle', data],
    queryFn: () => getTradeInBlock(data),
    ...config,
  });
};
