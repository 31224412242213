import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { VehicleList } from '../../../components/purchases/VehicleList';
import { Filters } from '../../../components/Filters';
import { useFilters } from '../../../api/getFilters';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const VehiclesPurchases = () => {
  const [filters, setFilters] = React.useState(null);
  const [filtersId, setFiltersId] = React.useState(Date.now());
  const [status, setStatus] = React.useState([7, 3]);
  const [checkedPending, setCheckedPending] = React.useState(true);
  const [checkedSold, setCheckedSold] = React.useState(true);

  const { t } = useTranslation();

  const handleChangePending = () => {
    setCheckedPending(!checkedPending);
    if (!checkedPending) {
      if (status.indexOf(7) === -1) {
        status.push(7);
        setStatus(status);
      }
    } else {
      setStatus(status.filter((item) => item !== 7));
    }
  };
  const handleChangeSold = () => {
    setCheckedSold(!checkedSold);
    if (!checkedSold) {
      if (status.indexOf(3) === -1) {
        status.push(3);
        setStatus(status);
      }
    } else {
      setStatus(status.filter((item) => item !== 3));
    }
  };

  const section = 'purchases';
  const filtersQuery = useFilters([7,3], {queryKey: ['block-filters', section]});
  
  if (filtersQuery.isLoading) {
    return (
      <Box justifyContent='center' display='flex'>
        <CircularProgress size={100} />
      </Box>
    );
  }
  return (
    <Container maxWidth='xl'>
      <Box py={4} width='100%' display='flex'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <Filters
              key={filtersId}
              filterValues={filtersQuery.data}
              setFilters={setFilters}
              setFiltersId={setFiltersId}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid
              container
              direction='row'
              justifyContent='flex-end'
              alignItems='center'
              sx={{ mb: 2 }}
              spacing={1}
            >
              <Grid item xs='auto'>
                <Chip
                  label={t('Sale accepted')}
                  icon={checkedSold ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                  color={checkedSold ? 'success' : 'default'}
                  onClick={handleChangeSold}
                />
              </Grid>
              <Grid item xs='auto'>
                <Chip
                  label={t('Pending sale')}
                  icon={
                    checkedPending ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />
                  }
                  color={checkedPending ? 'warning' : 'default'}
                  onClick={handleChangePending}
                />
              </Grid>
            </Grid>
            {status.length > 0 ? (
              <VehicleList
                filters={{
                  ...filters,
                  ShowOnlyPurchases: true,
                  LanguageId: 1 /* hardcoded => TODO */,
                  StatusId: status /* (Pending sold) (Sold) */,
                }}
              />
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

// [
//   {
//       "Id": 1,
//       "Name": "Comming soon"
//   },
//   {
//       "Id": 4,
//       "Name": "Expired"
//   },
//   {
//       "Id": 2,
//       "Name": "Live"
//   },
//   {
//       "Id": 8,
//       "Name": "Out of market"
//   },
//   {
//       "Id": 5,
//       "Name": "Pending"
//   },
//   {
//       "Id": 6,
//       "Name": "Pending offer"
//   },
//   {
//       "Id": 7,
//       "Name": "Pending sold"
//   },
//   {
//       "Id": 3,
//       "Name": "Sold"
//   }
// ]
