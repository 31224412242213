import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { VehicleList } from '../../../components/offers/VehicleList';
import { Filters } from '../../../components/Filters';
import { useFilters } from '../../../api/getFilters';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const VehiclesOffers = () => {
  const [filters, setFilters] = React.useState(null);
  const [filtersId, setFiltersId] = React.useState(Date.now());
  const [offerStatus, setStatus] = React.useState([1, 2]);
  const [checkedPending, setCheckedPending] = React.useState(true);
  const [checkedAccepted, setCheckedAccepted] = React.useState(true);
  const [checkedLost, setCheckedLost] = React.useState(false);

  const { t } = useTranslation();

  const handleChangePending = () => {
    setCheckedPending(!checkedPending);
    if (!checkedPending) {
      if (offerStatus.indexOf(1) === -1) {
        offerStatus.push(1);
        setStatus(offerStatus);
      }
    } else {
      setStatus(offerStatus.filter((item) => item !== 1));
    }
  };
  const handleChangeAccepted = () => {
    setCheckedAccepted(!checkedAccepted);
    if (!checkedAccepted) {
      if (offerStatus.indexOf(2) === -1) {
        offerStatus.push(2);
        setStatus(offerStatus);
      }
    } else {
      setStatus(offerStatus.filter((item) => item !== 2));
    }
  };

  const handleChangeLost = () => {
    setCheckedLost(!checkedLost);
    if (!checkedLost) {
      if (offerStatus.indexOf(3) === -1) {
        offerStatus.push(3);
        setStatus(offerStatus);
      }
    } else {
      setStatus(offerStatus.filter((item) => item !== 3));
    }
  };

  const section = 'offer';
  const filtersQuery = useFilters([3], {queryKey: ['block-filters', section]});
  
  if (filtersQuery.isLoading) {
    return (
      <Box justifyContent='center' display='flex'>
        <CircularProgress size={100} />
      </Box>
    );
  }

  return (
    <Container maxWidth='xl'>
      <Box py={4} width='100%' display='flex'>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <Filters
              key={filtersId}
              filterValues={filtersQuery.data}
              setFilters={setFilters}
              setFiltersId={setFiltersId}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid
              container
              direction='row'
              justifyContent='flex-end'
              alignItems='center'
              sx={{ mb: 2 }}
              spacing={1}
            >
              <Grid item xs='auto'>
                <Chip
                  color={checkedAccepted ? 'success' : 'default'}
                  label={t('Offer accepted')}
                  icon={
                    checkedAccepted ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />
                  }
                  onClick={handleChangeAccepted}
                />
              </Grid>
              <Grid item xs='auto'>
                <Chip
                  label={t('Pending offer')}
                  icon={
                    checkedPending ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />
                  }
                  color={checkedPending ? 'warning' : 'default'}
                  onClick={handleChangePending}
                />
              </Grid>
              <Grid item xs='auto'>
                <Chip
                  label={t('Lost offer')}
                  icon={checkedLost ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                  color={checkedLost ? 'error' : 'default'}
                  onClick={handleChangeLost}
                />
              </Grid>
            </Grid>
            <VehicleList
              offerStatus={offerStatus}
              filters={{
                ...filters,
                ShowOnlyOffers: true,
                LanguageId: 1 /* hardcoded => TODO */,
                StatusId: [2, 6] /* (live, Pending offer) */,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

// [
//   {
//       "Id": 1,
//       "Name": "Comming soon"
//   },
//   {
//       "Id": 4,
//       "Name": "Expired"
//   },
//   {
//       "Id": 2,
//       "Name": "Live"
//   },
//   {
//       "Id": 8,
//       "Name": "Out of market"
//   },
//   {
//       "Id": 5,
//       "Name": "Pending"
//   },
//   {
//       "Id": 6,
//       "Name": "Pending offer"
//   },
//   {
//       "Id": 7,
//       "Name": "Pending sold"
//   },
//   {
//       "Id": 3,
//       "Name": "Sold"
//   }
// ]
