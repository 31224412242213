import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SpecCard from '../../components/SpecCard';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

export const VehicleConditions = (props) => {

    const { conditions } = props;

    function getConditionLabel(condition) {

        switch (condition) {
            case 2:
                return 'Fair';
            case 3:
                return 'Good';
            case 4:
                return 'Very Good';
            case 5:
                return 'Excellent';
            default:
                return '';
        }
    }

  return (
    <Paper sx={{ flexGrow: 1, padding: 2 }}>
      <Box m={1}>
        <Typography component='span' variant='h5' fontWeight='500'>
          Conditions
        </Typography>
        <Divider sx={{ my: 2 }} />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard label='Issues' value={conditions.issues ? 'Yes' : 'No'} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard label='Defects' value={conditions.defects ? 'Yes' : 'No'} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard label='Damages' value={conditions.damages ? 'Yes' : 'No'} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard
            label='Modifications'
            value={conditions.modifications ? 'Yes' : 'No'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard label='Condition' value={getConditionLabel(conditions.conditions)} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard label='Accidented' value={conditions.accidented ? 'Yes' : 'No'} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard label='Tire Condition' value={conditions.tireCondition ?? ''} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard
            label='Second Tires Set'
            value={conditions.secondTiresSet ? 'Yes' : 'No'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard
            label='Accidented Wind Shield'
            value={conditions.accidentedWindShield ? 'Yes' : 'No'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard label='Smoked In' value={conditions.smokedIn ? 'Yes' : 'No'} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SpecCard label='Numbers Of Keys' value={conditions.numbersOfKeys} />
        </Grid>
      </Grid>
    </Paper>
  );
};
