import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useVehicle } from '../../../api/getVehicle';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { VehicleImages } from '../../../components/VehilcleDetails/VehicleImages';
import { VehicleBasicInformation } from '../../../components/VehilcleDetails/VehicleBasicInformation';
import { VehicleConditions } from '../../../components/VehilcleDetails/VehicleConditions';
import { VehicleNotes } from '../../../components/VehilcleDetails/VehicleNotes';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import { useAuth } from '@/contexts/auth';

export const VehicleDetailsMarketHistory = () => {
  let { id } = useParams();
  const { user } = useAuth();
  // const PrettyJson = (data) => {
  //   return <pre>{JSON.stringify(data, null, 2)}</pre>;
  // };

  const vehicleQuery = useVehicle({
    data: { id: id },
  });

  if (vehicleQuery.isLoading) {
    return (
      <Box mx='auto' my='auto'>
        <CircularProgress size={100} />
      </Box>
    );
  }
  const vehicle = vehicleQuery?.data;

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 1500, mx: 'auto' }} m={2}>
      <Paper sx={{ flexGrow: 1, position: 'relative' }}>
        <VehicleImages images={vehicle.images} imagesCustom={vehicle.imagesCustom} />
        <VehicleBasicInformation vehicle={vehicle} />
        <Box
          component='div'
          px={1}
          sx={{
            color: '#fff',
            position: 'absolute',
            top: '8px',
            left: '8px',
            fontSize: '30px',
            cursor: 'pointer',
            textShadow: '2px 2px black',
          }}
        >
          <FullscreenIcon fontSize='30px' />
        </Box>
      </Paper>
      <VehicleConditions conditions={vehicle.conditions} />
      <Grid key='details' container item xs={12} sx={{ mt: 1 }}>
        <VehicleNotes languageId={user.Language_ID} notes={vehicle.notes} />
      </Grid>
      {/* <Paper>
        <PrettyJson data={vehicle} />
      </Paper> */}
    </Box>
  );
};
