import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const TermsAndConditionsEN = () => {
  return (
    <Box sx={{ p: 2, width: '100%', maxWidth: 900, margin: 'auto' }}>
      <Typography variant='h4' component='h4' sx={{ m: 4 }} align='center'>
        Terms and conditions
      </Typography>
      <div>
        <p>
          Welcome to SHOPITRADE.com&apos;s website, a site created, powered and maintained
          by SHOPICAR INC. You are invited to access, browse and use this site in
          accordance with the following legal conditions (&apos;Legal Terms and
          Conditions&apos;).
        </p>
        <p>
          By accessing, browsing and using this site means you accept these Legal Terms
          and Conditions, constituting a binding contract between you and SHOPITRADE.com,
          SHOPITRADE or any affiliate, if applicable. SHOPITRADE.com, SHOPITRADE and
          affiliates encourage you to read the Legal Terms and Conditions, including the
          SHOPITRADE.com privacy policy. If you do not accept these terms, please do not
          use this site. If at any time during use of this site, you disagree with these
          Legal Terms and Conditions, kindly cease the use of this site.
        </p>
        <p>
          <strong>Intellectual property rights notices</strong>
        </p>
        <p>
          <strong>
            Copyright © SHOPITRADE.com, SHOPITRADE and affiliates. All rights reserved.
          </strong>
        </p>
        <p>
          All content on this site, including but not limited to, all software, text,
          data, sound, programming, images, graphics and design of the site, and the
          selection, organization and arrangement of the foregoing (collectively, the
          &apos;Content&apos;) are protected by copyright and other intellectual property
          rights and are owned or used under license by SHOPITRADE.com, SHOPITRADE or the
          applicable affiliate.
        </p>
        <p>
          The trademarks, service marks, logos and other marks used on this site by
          SHOPITRADE.com, SHOPITRADE and the affiliates (the &apos;Trademarks&apos;) are
          either owned or controlled by SHOPITRADE.com, SHOPITRADE or the applicable
          affiliate. The list of SHOPITRADE.com trademarks are incorporated by reference
          into and form part of these Legal Terms and Conditions. It is forbidden to use
          these trademarks without the prior written permission of SHOPITRADE.com,
          SHOPITRADE or the applicable affiliates and may not be used in connection with
          any product or service in any manner likely to cause confusion among customers,
          or in a manner that disparages or discredits SHOPITRADE.com, SHOPITRADE or the
          applicable affiliate. All trademarks, service marks, logos and other marks that
          appear on this site are property of their respective owners.
        </p>
        <p>
          You agree to comply with notice of copyright, or additional restrictions
          contained in any of the content.
        </p>
        <p>
          <strong>Restrictions on use</strong>
        </p>
        <p>
          You can download and use a reasonable number of copies of the content on any
          single computer for your own personal, non-commercial use provided that: (a)
          keep intact the notices of copyright and intellectual property (b) do not modify
          the content, and (c) not use the content to suggest an association with
          SHOPITRADE.com, SHOPITRADE or an affiliate or any of their respective products,
          services or brands. You may not modify, publish, transmit, transfer, sell,
          reproduce, upload, post, distribute, create derivative works from, or in any way
          exploit any of the content, in whole or in part, except as SHOPITRADE.com.
          SHOPITRADE or the applicable affiliate expressly permits in these Legal Terms
          and Conditions. Your use of the content for any purpose other than those
          expressly authorized (including your use of the content on any other website or
          networked computer environment) is a violation of intellectual property rights
          and other property rights of SHOPITRADE.com, SHOPITRADE or the applicable
          affiliate and may subject you to civil liability or criminal prosecution under
          applicable laws.
        </p>
        <p>
          <strong>Canadian content</strong>
        </p>
        <p>
          The vehicles, products and services described on this site contain uniquely
          Canadian specifications and features available only in Canada to Canadian
          residents and only where permitted by law. Prices and other financial
          information published on this site are in Canadian dollars unless
          SHOPITRADE.com, SHOPITRADE or the applicable affiliate state otherwise and
          SHOPITRADE.com, SHOPITRADE or the applicable affiliate are allowed to change
          prices and other financial information, employment opportunities and other
          information provided on this site at any time without notice.
        </p>
        <p>
          <strong>Links to 3rd party sites</strong>
        </p>
        <p>
          This site may contain hyperlinks to third party websites. The use of such
          websites linked to this site is not the responsibility of SHOPITRADE.com.
          SHOPITRADE.com, SHOPITRADE or affiliates cannot ensure the availability,
          reliability and accuracy of the content of a web site accessible through a link
          or any link contained in a web site accessible through a hyperlink.
          SHOPITRADE.com, SHOPITRADE or the affiliate provide these links to you only as a
          convenience, and the inclusion of any link does not imply and is not supported
          by SHOPITRADE.com, SHOPITRADE or the applicable affiliate for the website
          accessible through a link or content.
        </p>
        <p>
          <strong>Privacy Policy</strong>
        </p>
        <p>
          During your use of this site, you may voluntarily submit personal information to
          SHOPITRADE.com, SHOPITRADE or an affiliate. SHOPITRADE.com, SHOPITRADE and the
          applicable affiliate encourage you to read the SHOPITRADE.com Privacy Policy
          (the &apos;Privacy Policy&apos;) before using this site. By using this site, you
          agree to the Privacy Policy which describes how personal information collected
          through this site is handled by SHOPITRADE.com, SHOPITRADE and the applicable
          affiliate. If you do not agree with the Privacy Policy, do not provide any
          personal information.
        </p>
        <p>
          <strong>Changes to Legal Terms and Conditions</strong>
        </p>
        <p>
          SHOPITRADE.com, SHOPITRADE and affiliates reserve the right to change at any
          time these Legal Terms and Conditions and you agree to these changes. These
          changes will take effect upon delivery of notice by SHOPITRADE.com, SHOPITRADE
          or the applicable affiliate in any form whatsoever, including, without
          limitation, posting on the site. You agree to regularly review these Legal Terms
          and Conditions posted on this site to keep you informed of such changes. Use of
          this site following such changes constitutes your agreement to follow and be
          bound to the changed Legal Terms and Conditions. If at any time, these Legal
          Terms and Conditions are no longer acceptable, you must cease immediately the
          use of this site.
        </p>
        <h6>Modification or termination of service</h6>
        <p>
          SHOPITRADE.com, SHOPITRADE and affiliates reserve the right at their discretion,
          to restrict, suspend or terminate your access to this site, in whole or in part,
          at any time and for any reason, without notice or liability to you.
          SHOPITRADE.com, SHOPITRADE and the affiliates may at any time amend, suspend or
          discontinue the site, in whole or in part, without notice or liability to you.
        </p>
        <h6>Jurisdiction, Governing Law and Arbitration</h6>
        <p>
          SHOPITRADE.com, SHOPITRADE and the affiliates make no representation that the
          content on the site is appropriate or available for a particular region. You are
          responsible for compliance with local laws in your place of residence to the
          extent that such laws are applicable. These Legal Terms and Conditions and use
          of this site will be governed by and construed in accordance with the laws of
          the province of Quebec and the federal laws of Canada applicable therein,
          without reference to any choice of law or principle of conflict of laws. The
          United Nations Convention on Contracts for the International Sale of Goods does
          not apply to these Legal Terms and Conditions or any communication to
          transactions with or through the site.
        </p>
        <h6>General</h6>
        <p>
          If any provision of these Legal Terms and Conditions is unlawful, void or
          otherwise unenforceable, such provision shall be deemed severable from these
          Legal Terms and Conditions and shall not affect the validity and enforceability
          of any remaining provisions. Sections 1, 2, 4, 5, 6 and 7 shall survive the
          termination of this agreement and any other provisions which by their terms or
          by its nature is intended to survive. These Legal Terms and Conditions
          constitute the entire agreement between you and SHOPITRADE.com, SHOPITRADE and /
          or the affiliates and supersede all prior agreements between you and
          SHOPITRADE.com, SHOPITRADE and / or the affiliates for your use of this site.
        </p>
        <h6>Language</h6>
        <p>
          Les parties déclarent qu&apos;elles ont demandé que cette entente et tout
          document s&apos;y rapportant soient préparés en anglais. The parties to this
          agreement declare that they have requested that this agreement and all ancillary
          documents be prepared in the English language.
        </p>
        <h5>
          <strong>PRIVACY POLICY - SHOPITRADE.COM</strong>
        </h5>
        <p>
          With the growth of new communication tools, it is absolutely necessary to pay
          attention to the protection of privacy. Therefore, SHOPITRADE and SHOPITRADE.com
          undertake to respect the confidentiality of personal information they collect
          during your visit to this site.
        </p>
        <h6>Forms and interactivity</h6>
        <p>
          The personal information we collect comes from the forms you complete and
          through interactivity between you and our website. We may use personal
          information to contact a third party in ways to validate the personal and
          financial information collected, make a credit application to financial
          institutions, offer promotions, contact you or compile statistics.
        </p>
        <h6>Log files and cookies</h6>
        <p>
          We collect certain information through log files and cookies. This information
          is mainly the following: IP address, operating system, pages visited and
          queries, hours and days of connection. The information provided is for
          statistical purposes only and does not disclose your identity in any way. No
          identifiable information is collected by these cookies and log files.
        </p>
        <h6>Sharing of personal information</h6>
        <p>
          With your permission, SHOPITRADE.com will send you the relevant information on
          its promotions, its partners or third parties, including information about new
          products and services of these partners. We will apply the necessary means to
          ensure that you will not receive any spam. If you do not want your personal
          information to be disclosed to third parties, it is possible for you to disallow
          at any time thereafter.
        </p>
        <h6>Security</h6>
        <p>
          The personal information we collect is maintained in a secure environment.
          Individuals in our service are required to respect the confidentiality of your
          personal information. To ensure the security of your personal information, we
          use the following measures: SSL (Secure Sockets Layer), access management, user
          ID / password, data backup and firewalls. We are also committed to maintaining a
          high degree of confidentiality by incorporating the latest technological
          innovations to ensure the confidentiality of your transactions.
        </p>
        <h6>External links</h6>
        <p>
          This website contains links to other sites. SHOPITRADE.com and SHOPITRADE are
          not responsible for the privacy policies or the content of such websites. To
          ensure the protection of your personal information, we recommend that you review
          the privacy policy of any site you access through a link on
          SHOPITRADE.com&apos;s website.
        </p>
        <h6>Website access</h6>
        <p>
          This website is hosted on servers located in Canada. Therefore, your connection
          will be through servers located in Canada. All personal information you provide
          during your visit will be processed and stored on our web server and our other
          internal systems located in Canada.
        </p>
        <h6>Password</h6>
        <p>
          The personal data record created when you register for SHOPITRADE.com is
          accessible only through a unique password associated with it. To protect the
          integrity of personal information contained in this website, you must not reveal
          in any way your password to third parties.
        </p>
        <p>
          <strong>Public forums</strong>
        </p>
        <p>
          SHOPITRADE.com&apos;s website may provide to its users blogs, forums, message
          boards and / or other groups. Please note that unless otherwise indicated, all
          information disclosed in these areas becomes public. Therefore, you must be
          vigilant when you choose to disclose information in these areas.
        </p>
        <p>
          <strong>Privacy policy subject to changes</strong>
        </p>
        <p>
          We reserve the right to modify and update at any time this privacy policy when
          we deem appropriate. We encourage you to periodically review our privacy policy
          to ensure you are aware of the changes that we could have made.
        </p>
        <p>
          Please contact us for any questions about this privacy policy or the practices
          of this website.
        </p>
        <p>
          <strong>SHOPITRADE.com and SHOPITRADE</strong>
        </p>
        <p>
          <strong>SHOPITRADE © All rights reserved</strong>
        </p>
      </div>
    </Box>
  );
};
