import * as React from 'react';
import { useLocation, Outlet, Link } from 'react-router-dom';
import { MainLayout } from '@/components/Layout';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';

export const Block = () => {
  const { pathname } = useLocation();
  const [value, setValue] = React.useState(pathname.split('/')[1]);
  const { t } = useTranslation();
  

  const handleChange = (event, newValue) => setValue(newValue);
  const TabStyle = { textTransform: 'none', fontSize: '17px', color: '#444' };
  const tabStyle2 = { textTransform: 'none', fontSize: '14px', color: '#666' };

  React.useEffect(() => {      
    setValue(pathname.split('/')[1]);
  }, [pathname]);

  return (
    <MainLayout>
      <React.Suspense fallback={<CircularProgress size={100} />}>
        <Stack width='100%'>
          <Box
            sx={{ borderBottom: 1, borderColor: 'divider' }}
            display='flex'
            justifyContent='center'
            width='100%'
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab
                sx={TabStyle}
                value='upcoming'
                to='upcoming'
                label={t('Coming Soon')}
                component={Link}
              />
              <Tab
                sx={TabStyle}
                value='live'
                to='live'
                label={t('Live')}
                component={Link}
              />
              <Tab
                sx={TabStyle}
                value='offers'
                to='offers'
                label={t('My Offers')}
                component={Link}
              />
              <Tab
                sx={TabStyle}
                value='purchases'
                to='purchases'
                label={t('Purchases')}
                component={Link}
              />
              <Tab
                label=''
                icon={<Divider orientation='vertical' variant='middle' flexItem />}
                disabled
                sx={{ p: 0, m: 0, minWidth: '5px' }}
              />
              <Tab
                sx={tabStyle2}
                value='marketHistory'
                to='marketHistory'
                label={t('Market history')}
                component={Link}
              />
            </Tabs>
          </Box>
          <Box>
            <Outlet />
          </Box>
        </Stack>
      </React.Suspense>
    </MainLayout>
  );
};
