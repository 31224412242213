import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Moment from 'react-moment';

export const VehicleActionSideBarPurchases = (props) => {
  const { vehicle } = props;

  const priceFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  return (
    <Paper sx={{ flexGrow: 1, padding: 2 }}>
      <Stack spacing={2} width='100%'>
        <Typography
          component='div'
          variant='p'
          fontWeight='500'
          fontSize='23px'
          align='center'
          sx={{
            mt: 0,
            color: '#fff',
            borderRadius: '4px',
            backgroundColor: '#52a9dd',
            pr: 3,
            pl: 3,
            mr: 'auto',
            ml: 'auto',
          }}
        >
          {vehicle.status}
        </Typography>
        <Divider />
        {vehicle.showPrice && (
          <>
            <Typography
              component='span'
              variant='h4'
              color='primary'
              fontWeight='500'
              align='center'
            >
              {priceFormatter.format(vehicle.price)}
            </Typography>
            <Divider />
          </>
        )}
        
        <Box>
          <Typography
            component='div'
            variant='p'
            fontSize='18px'
            align='center'
            color='#555'
          >
            Date of purchase
          </Typography>
          <Typography
            component='div'
            variant='p'
            fontWeight='500'
            fontSize='18px'
            align='center'
            sx={{ m: 0 }}
          >
            <Moment format='LLL'>{vehicle.purchaseDate.replace('T', ' ')  + ' UTC'}</Moment>
          </Typography>
        </Box>
        <Divider />
      </Stack>
    </Paper>
  );
};
